import React, { useCallback, useEffect, useState } from "react";
import CustomTable from "../../../shared/CustomTable";
import CustomButton from "../../../shared/CustomButton";

const InboundTableList = ({
  pagination,
  onPaginationHandler = () => {},
  tableRow = [],
  onLeadIDClick = () => {},
}) => {
  const [tableRowDataList, setTableRowDataList] = useState([]);

  const leadIDRender = useCallback(
    (row) => {
      return (
        <div
          className="cursor-pointer fw-bold"
          style={{ color: "rgba(6, 116, 140, 1)" }}
          onClick={() => {
            onLeadIDClick(row);
          }}
        >
          {row?.leadID}
        </div>
      );
    },
    [onLeadIDClick]
  );
  const statusOptionRender = useCallback((row) => {
    return (
      <div style={{ display: "flex", gap: "10px" }}>
        <CustomButton
          className=""
          style={{
            color:
              row?.status === "New"
                ? "black"
                : row?.status === "Connected"
                ? "white"
                : "",
            width: "90px",
            backgroundColor:
              row?.status === "New"
                ? "rgba(120, 225, 225, 1)"
                : row?.status === "Connected"
                ? "rgba(255, 122, 0, 1)"
                : "rgba(255, 214, 110, 1)",
          }}
        >
          {row?.status}
        </CustomButton>
      </div>
    );
  }, []);

  const TableColumnDataList = [
    {
      id: 1,
      label: "Lead ID",
      rowId: "leadIDRender",
      position: "center",
      isRenderItem: true,
    },
    {
      id: 2,
      label: "Customer",
      rowId: "cunstomer",
      position: "center",
      isRenderItem: false,
    },
    {
      id: 3,
      label: "Tele Caller",
      rowId: "telecaller",
      position: "center",
      isRenderItem: false,
    },
    {
      id: 4,
      label: "Policy Type",
      rowId: "policyType",
      position: "center",
      isRenderItem: false,
    },
    {
      id: 5,
      label: "End Date",
      rowId: "endDate",
      position: "center",
      isRenderItem: false,
    },
    {
      id: 6,
      label: "Registration Number",
      rowId: "registrationNumber",
      position: "center",
      isRenderItem: false,
    },
    {
      id: 7,
      label: "Engine Number ",
      rowId: "engineNumber",
      position: "center",
      isRenderItem: false,
    },
    {
      id: 8,
      label: "Chassis Number",
      rowId: "chasisNumber",
      position: "center",
      isRenderItem: false,
    },
    {
      id: 9,
      label: "Email",
      rowId: "email",
      position: "center",
      isRenderItem: false,
    },
    {
      id: 10,
      label: "Mobile",
      rowId: "mobile",
      position: "center",
      isRenderItem: false,
    },
    {
      id: 11,
      label: "Product Name",
      rowId: "productName",
      position: "center",
      isRenderItem: false,
    },
    {
      id: 12,
      label: "Disposition",
      rowId: "disposition",
      position: "center",
      isRenderItem: false,
    },
    {
      id: 13,
      label: "Sub Disposition",
      rowId: "subDisp",
      position: "center",
      isRenderItem: false,
    },

    {
      id: 14,
      label: "Status",
      rowId: "status",
      position: "center",
      isRenderItem: false,
    },
  ];

  useEffect(() => {
    if (tableRow) {
      setTableRowDataList(
        tableRow.map((value) => ({
          ...value,

          status: statusOptionRender(value),
          leadIDRender: leadIDRender(value),
        }))
      );
      // setTableRowDataList(updatedTableRowDataList);
    }
  }, [leadIDRender, statusOptionRender, tableRow]);

  return (
    <div>
      <CustomTable
        isSelectedCheckbox
        tableColumn={TableColumnDataList}
        tableRow={tableRowDataList}
        peginations={pagination}
        onPeginations={onPaginationHandler}
      />
    </div>
  );
};

export default InboundTableList;
