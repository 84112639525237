export const CALL_HOSTORY_DATA = [
  {
    id: 0,
    callpurpose: "Follow Up",
    owner: "Abhishek Kumar Modi",
    callTimeDuration: "20 Feb 2024 06:26",
    callStatus: "Ringing not received",
    nextFollowUp: "Apr 20 2024 11.00 AM",
    recordings: "Manually Dialed",
    callStatusid: 1,
  },
  {
    id: 1,
    callpurpose: "Renew",
    owner: "Abhishek Kumar Modi",
    callTimeDuration: "20 Feb 2024 06:26",
    callStatus: "Marked as Follow up",
    nextFollowUp: "Apr 20 2024 11.00 AM",
    recordings: "Manually Dialed",
    callStatusid: 0,
  },
  {
    id: 2,
    callpurpose: "Payment",
    owner: "Abhishek Kumar Modi",
    callTimeDuration: "20 Feb 2024 06:26",
    callStatus: "Converted Successfully",
    nextFollowUp: "Apr 20 2024 11.00 AM",
    recordings: "Manually Dialed",
    callStatusid: 2,
  },
];

export const CALL_STATUS_DATA = [
  {
    id: 0,
    referenceNo: "P-0254721458354",
    dateTime: "20 Feb 2024 06:26",
    time: "06:26",
    cKYCStatus: "Completed",
    oTPVerification: "Pending",
    cKYCStatusid: 2,
    oTPVerificationid: 0,
    paymentid: 1,
    payment: "Due",
  },
];

export const POLIXY_STATUS_DATA = [
  {
    id: 0,
    referenceNo: "P-0254721458354",
    date: "01-06-2023",
    time: "20-01-2023 06:26",
    dateTime: "20 Feb 2024 06:26",
    status: "Policy Done",
    attchment: "Lorem alat kail.pdf",
    cKYCStatusid: 2,
    attchmentid: 0,
    paymentid: 1,
    payment: "Due",
  },
  {
    id: 1,
    referenceNo: "P-0254721458354",
    date: "20-01-2023",
    time: "20-01-2023 06:26",
    status: "Proposal",
    attchment: "Lorem alat kail.pdf",
    cKYCStatusid: 2,
    attchmentid: 0,
    paymentid: 1,
    payment: "Due",
  },
  {
    id: 3,
    referenceNo: "P-0254721458354",
    date: "20-06-2024",
    time: "20-06-2023 06:26",
    status: "Quotation",
    attchment: "Lorem alat kail.pdf",
    cKYCStatusid: 2,
    attchmentid: 0,
    paymentid: 1,
    payment: "Due",
  },
];
