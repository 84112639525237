// // userAuthSlice.js

// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   isAuthenticated: false,
//   role: null,
// };

// export const userAuthSlice = createSlice({
//   name: "userAuth",
//   initialState,
//   reducers: {
//     setUserAuth: (state, action) => {
//       state.isAuthenticated = action.payload.isAuthenticated;
//       state.role = action.payload.role;

//       // Update localStorage
//       localStorage.setItem("isAuthenticated", action.payload.isAuthenticated);
//       localStorage.setItem("Roles", action.payload.role);
//     },
//     clearUserAuth: (state) => {
//       state.isAuthenticated = false;
//       state.role = null;

//       // Clear localStorage
//       localStorage.removeItem("isAuthenticated");
//       localStorage.removeItem("Roles");
//     },
//   },
// });

// export const { setUserAuth, clearUserAuth } = userAuthSlice.actions;

// export default userAuthSlice.reducer;

// userAuthSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  role: null,
};

export const userAuthSlice = createSlice({
  name: "userAuth",
  initialState,
  reducers: {
    setUserAuth: (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.role = action.payload.role;

      // Update localStorage
      // localStorage.setItem("isAuthenticated", action.payload.isAuthenticated);
      // localStorage.setItem("Roles", action.payload.role);
    },
    clearUserAuth: (state) => {
      state.isAuthenticated = false;
      state.role = null;

      // Clear localStorage
      // localStorage.removeItem("isAuthenticated");
      // localStorage.removeItem("Roles");
    },
  },
});

export const { setUserAuth, clearUserAuth } = userAuthSlice.actions;

export default userAuthSlice.reducer;
