import React, { useCallback, useEffect, useState } from "react";
import CustomTable from "../../../../shared/CustomTable";
import playicon from "../../../../../assets/icons/Vector.svg";
import "./LeadManagmentCallStatus.scss";
const LeadManagmentClaimTableList = ({
  pagination,
  onPaginationHandler = () => {},
  tableRow = [],
  onEdit = () => {},
  onDelete = () => {},
  onProposalClick = () => {},
  tableLoaderShow = false,
}) => {
  const [tableRowDataList, setTableRowDataList] = useState();

  const actionOptionRender = useCallback((row) => {
    return (
      <div
        style={{
          display: "flex",
          // gap: "10px",
        }}
      >
        <div className="dropdown-item call-history-icon">
          <button
            className="align-middle me-1 cursor-pointer play-icon"
            onClick={() => {}}
          >
            <img
              src={playicon}
              className="bi bi-play-circle "
              alt="Play Icon"
            />
          </button>
        </div>
        <hr />
        <div className="dropdown-item call-history-icon">
          <button className="align-middle me-1 cursor-pointer button-icon">
            <i className="bi bi-download" data-feather="pie-chart"></i>
          </button>
        </div>
      </div>
    );
  }, []);

  const statusOptionRender = useCallback((row) => {
    return (
      <div
        style={{
          display: "flex",
          gap: "10px",
        }}
      >
        <div
          className=""
          style={{
            color:
              row?.oTPVerificationid === 1
                ? "rgba(198, 33, 33, 1)"
                : row?.oTPVerificationid === 0
                ? "rgba(188, 153, 61, 1)"
                : "rgba(22, 163, 74, 1)",
          }}
        >
          {row?.oTPVerification}
        </div>
      </div>
    );
  }, []);
  const proposalOptionRender = useCallback(
    (row) => {
      return (
        <div
          className="cursor-pointer"
          onClick={() => {
            onProposalClick(row);
          }}
          style={{
            display: "flex",
            gap: "10px",
          }}
        >
          <div className="text-blue fw-semibold">{row?.proposalNo}</div>
        </div>
      );
    },
    [onProposalClick]
  );

  const priorityOptionRender = useCallback((row) => {
    return (
      <div
        style={{
          display: "flex",
          gap: "10px",
        }}
      >
        <div
          className=""
          style={{
            color:
              row?.cKYCStatusid === 1
                ? "rgba(198, 33, 33, 1)"
                : row?.cKYCStatusid === 0
                ? "rgba(188, 153, 61, 1)"
                : "rgba(22, 163, 74, 1)",
          }}
        >
          {row?.cKYCStatus}
        </div>
      </div>
    );
  }, []);

  const paymentOptionRender = useCallback((row) => {
    return (
      <div
        style={{
          display: "flex",
          gap: "10px",
        }}
      >
        <div
          className=""
          style={{
            color:
              row?.paymentid === 1
                ? "rgba(198, 33, 33, 1)"
                : row?.paymentid === 0
                ? "rgba(188, 153, 61, 1)"
                : "rgba(22, 163, 74, 1)",
          }}
        >
          {row?.payment}
        </div>
      </div>
    );
  }, []);

  const TableColumnDataList = [
    {
      id: 1,
      label: "Proposal No.",
      rowId: "proposalOptionRender",
      position: "Proposal No.",
      isRenderItem: true,
    },
    {
      id: 2,
      label: "Date & Time",
      rowId: "dateTime",
      position: "center",
      isRenderItem: false,
    },
    // {
    //   id: 3,
    //   label: "Time",
    //   rowId: "time",
    //   position: "center",
    //   isRenderItem: false,
    // },
    {
      id: 4,
      label: "CKYC Status",
      rowId: "cKYCStatus",
      position: "center",
      isRenderItem: true,
    },
    {
      id: 5,
      label: "OTP Verification",
      rowId: "oTPVerification",
      position: "center",
      isRenderItem: false,
    },

    {
      id: 6,
      label: "Payment",
      rowId: "payment",
      position: "center",
      isRenderItem: true,
    },
  ];

  useEffect(() => {
    if (tableRow) {
      setTableRowDataList(
        tableRow?.map((value) => {
          return {
            ...value,

            oTPVerification: statusOptionRender(value),
            proposalOptionRender: proposalOptionRender(value),
            cKYCStatus: priorityOptionRender(value),
            payment: paymentOptionRender(value),
          };
        })
      );
    }
  }, [
    priorityOptionRender,
    statusOptionRender,
    paymentOptionRender,
    tableRow,
    proposalOptionRender,
  ]);

  return (
    <div>
      <CustomTable
        tableLoaderShow={tableLoaderShow}
        tableColumn={TableColumnDataList}
        tableRow={tableRowDataList}
      />
    </div>
  );
};

export default LeadManagmentClaimTableList;
