import React, { useEffect, useRef } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import CustomModal from "../CustomModal";
import CustomButton from "../CustomButton";

const Confirmation = ({
  okLabel = "OK",
  cancelLabel = "Cancel",
  title = "",
  confirmation,
  show,
  proceed,
  enableEscape = true,
  headerText = "",
}) => {
  const OpenButtonRef = useRef();
  const CloseButtonRef = useRef();

  useEffect(() => {
    // if (show) {
    //   // document.body.style.overflow = "hidden";
    //   OpenButtonRef?.current.click();
    // } else {
    //   CloseButtonRef.current.click();
    //   proceed(false);
    //   // window.scrollTo(0, 0);
    //   // document.body.style.overflow = "scroll";
    // }
    // return () => {
    //   // document.body.style.overflow = "scroll";
    //   // window.scrollTo(0, 0);
    // };
  }, [proceed, show]);

  // const lockScroll = React.useCallback(() => {
  //   document.body.style.overflow = "hidden";
  // }, []);

  // const unlockScroll = React.useCallback(() => {
  //   console.log("----------unlockScroll-----------------");
  //   document.body.style.overflow = "scroll";
  // }, []);

  // useEffect(() => {
  //   lockScroll();
  //   return () => {
  //     unlockScroll();
  //   };
  // }, [lockScroll, unlockScroll]);

  return (
    <>
      {/* <button
        ref={OpenButtonRef}
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      ></button> */}

      {/* <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                {confirmation?.title || ""}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  e.preventDefault();
                  proceed(false);
                }}
              />
            </div>
            <div className="modal-body"> {confirmation?.description || ""}</div>
            <div className="modal-footer">
              <button
                ref={CloseButtonRef}
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  proceed(false);
                }}
              >
                {cancelLabel}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  proceed(true);
                }}
              >
                {okLabel}
              </button>
            </div>
          </div>
        </div>
      </div> */}

      {/* <CustomModal
        headerClass="shareModelHeader"
        isOpen={show}
        // isCloseButton={false}
        headerRender={() => {
          return (
            <div className="row" style={{ width: "100%" }}>
              <div className="fs-4 text-center warningModalHeading col-12">
                {confirmation?.title || ""}
              </div>
            </div>
          );
        }}
        onClose={() => {
          proceed(false);
          // setIsWarningPopup(false);
        }}
        // style={{
        //   width: "30rem",
        // }}
      >
        <div className="row mt-4 ">
          <div className="col-12 warning-popup-text px-5">
            {confirmation?.description || ""}
          </div>

          <div className="col-12 mt-5">
            <div className="d-flex justify-content-center align-items-center gap-1">
              <CustomButton
                isFilled
                buttonStyle={{ width: "20%", minWidth: "100px" }}
                title={cancelLabel}
                onClick={(e) => {
                  e.preventDefault();
                  proceed(false);
                }}
              />
              <CustomButton
                isFilled
                buttonStyle={{ width: "20%", minWidth: "100px" }}
                title={okLabel}
                onClick={(e) => {
                  e.preventDefault();
                  proceed(true);
                }}
              />
            </div>
          </div>
        </div>
      </CustomModal> */}
      <CustomModal
        show={false}
        handleClose={() => {
          // setRenewalModalOpen(false);
        }}
        handleSave={() => {
          // setRenewalModalOpen(false);
        }}
        title={confirmation?.title || ""}
        size="md"
      >
        <div className="row mt-4 ">
          <div className="col-12 warning-popup-text px-5">
            {confirmation?.description || ""}
          </div>
          <div className="col-12 mt-5">
            <div className="d-flex justify-content-center align-items-center gap-1">
              <CustomButton
                isFilled
                buttonStyle={{ width: "20%", minWidth: "100px" }}
                title={cancelLabel}
                onClick={(e) => {
                  e.preventDefault();
                  proceed(false);
                }}
              />
              <CustomButton
                isFilled
                buttonStyle={{ width: "20%", minWidth: "100px" }}
                title={okLabel}
                onClick={(e) => {
                  e.preventDefault();
                  proceed(true);
                }}
              />
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export function confirm(
  confirmation,
  proceedLabel = "OK",
  cancelLabel = "Cancel",
  options = {}
) {
  return createConfirmation(confirmable(Confirmation))({
    confirmation,
    proceedLabel,
    cancelLabel,
    ...options,
  });
}
