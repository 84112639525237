import React from "react";
import "./CustomDropdown.scss";

function CustomDropdown({
  label,
  viral,
  options,
  name,
  defaultValue,
  value,
  onChange = () => {},
  optionLabel,
  optionValue,
  onBlur,
  register,
  error,
  placeholder = "",
}) {
  return (
    <div className="custom-dropdown-container">
      <label className="form-label">{label}</label>
      {viral ? <span className="text-danger">*</span> : ""}
      <select
        // value={value}
        menuPlacement="top"
        minMenuHeight={500}
        className="form-select custom-rounded"
        // className="basic-single"
        // classNamePrefix="select"
        aria-label="Default select example"
        id="customDropdown"
        name={name}
        placeholder={placeholder}
        // onChange={onChange}
        onBlur={onBlur}
        value={value}
        {...(register ? register : {})}
        onChange={(e) => {
          if (register) register.onChange(e); // Call react-hook-form onChange
          if (onChange) onChange(e); // Call custom onChange if provided
        }}
      >
        <option value="">{placeholder}</option>
        {options?.map((option) => (
          <option key={option.id} value={option[optionValue || "value"]}>
            {option[optionLabel || "label"]}
          </option>
        ))}
      </select>
      {error && <div className="error-message-dropdown">{error.message}</div>}
    </div>
  );
}

export default CustomDropdown;
