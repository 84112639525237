import React, { useState } from "react";
import "./ownerShipAndClaim.scss";
import CustomRadioButton from "../../../../shared/CustomRadiobox";
import CustomButton from "../../../../shared/CustomButton";
const OwnershipAndClaim = ({
  onSave = () => {},
  onCancel = () => {},
  optionChange,
}) => {
  const [selectedOption1, setSelectedOption1] = useState("");
  const [selectedOption2, setSelectedOption2] = useState("no");
  const [isRequired1, setIsRequired1] = useState(false);
  const [isRequired2, setIsRequired2] = useState(false);

  const handleRadio1 = (e) => {
    setSelectedOption1(e?.value);

    optionChange(e?.value, selectedOption2);
  };
  const handleRadio2 = (e) => {
    setSelectedOption2(e?.value);
    optionChange(selectedOption1, e?.value);
  };
  console.log("selectedOption1", selectedOption1);
  console.log("selectedOption2", selectedOption2);

  const handleSubmit = () => {
    if (selectedOption1 === "") {
      setIsRequired1(true);
    } else if (selectedOption1 === "yes") {
      onSave();
    } else if (selectedOption1 === "no") {
      if (selectedOption2 === "") {
        setIsRequired2(true);
      } else {
        onSave();
      }
    }
  };

  console.log("selectedOption1", selectedOption1);
  return (
    <div className="radio-container">
      <div className="row">
        <div className="col-6 sub-Container">
          <div className="d-flex align-items-center">
            <div className="lable">Change in Ownership in Last 1 Year</div>{" "}
            <span className="text-danger ml-1">*</span>
          </div>
          <span>
            <CustomRadioButton name="ownerShip" onChange={handleRadio1} />
            {isRequired1 && (
              <span className="text-danger required">
                Please Select Change In Ownership
              </span>
            )}
            {/* <span className="text-danger required">
              Please Select Change In Ownership
            </span> */}
          </span>
        </div>
        {selectedOption1 !== "yes" && (
          <div className="col-6">
            <div className="d-flex align-items-center">
              <div className="lable">Have You Made a Claim?</div>
              <span className="text-danger ml-1">*</span>
            </div>
            <span>
              <CustomRadioButton name="claimMade" onChange={handleRadio2} />
              {isRequired2 && (
                <span className="text-danger required">
                  Please Select Made a Claim
                </span>
              )}
            </span>
          </div>
        )}
      </div>
      <div className="form-button-main d-flex justify-content-end mt-3">
        <CustomButton
          className="view-template-btn mr-2 me-2 text-center d-flex align-items-center justify-content-center"
          onClick={onCancel}
        >
          <span className="view-btn ">Cancel</span>
        </CustomButton>
        <CustomButton
          className="report-download-btn mr-2 text-center d-flex align-items-center justify-content-center"
          onClick={handleSubmit}
        >
          <span className="download-btn">Submit</span>
        </CustomButton>
        {/* {showPopup && (
            <CustomModal
              show={showPopup}
              handleClose={() => setShowPopup(false)}
              style={{ height: "30%" }}
            >
              <div className="col-sm-12 d-flex" style={{ padding: "5px" }}>
                <p>Leads have been assigned successfully!</p>
                <button className="custom-modal-close" onClick={handleClose}>
                  &times;
                </button>
              </div>
            </CustomModal>
          )} */}
      </div>
    </div>
  );
};

export default OwnershipAndClaim;
