import { Provider } from "react-redux";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import RouteComponent from "./routes";
import { store } from "./redux";
import { GlobalContextProvider } from "./context/GlobalContext";
import LoaderProvider from "./context/LoaderProvider";

function App() {
  return (
    <BrowserRouter>
      <GlobalContextProvider>
        <Provider store={store}>
          <LoaderProvider>
            <RouteComponent />
          </LoaderProvider>
        </Provider>
      </GlobalContextProvider>
    </BrowserRouter>
  );
}

export default App;
