import React, { useEffect, useState } from "react";
import "./Toast.scss";
import Icon from "@mdi/react";
import { mdiCheckCircle } from "@mdi/js";
// import checkadded from "../../../assets/icons/Check.svg";

const SuccessToast = ({ title, message, autoHideDuration, toastKey }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
    const data = setTimeout(() => {
      setOpen(false);
    }, 4000);
    return () => {
      clearTimeout(data);
    };
  }, [toastKey]);

  return (
    <div
      className={`toast-container position-fixed end-0 ${
        open ? "toast-open-animation" : "toast-close-animation"
      }`}
    >
      <div
        className={`toast align-items-center bg-green text-white border-0 p-2 justify-content-between ${
          open ? "show" : "hide"
        }`}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div className="d-flex justify-content-between">
          <div className=" d-flex gap-2 p-2 align-items-center ">
            <Icon path={mdiCheckCircle} size={0.8} />
            <strong className="me-auto fs-6">{title}</strong>
          </div>
          <button
            type="button"
            className="btn-close text-blue me-2 m-auto"
            data-bs-dismiss="toast"
            aria-label="Close"
          />
        </div>
        {message && <div className="toast-body">{message}</div>}
      </div>
    </div>
  );
};

export default SuccessToast;

// <Toast
//   anchorOrigin={{ vertical: "top", horizontal: "right" }}
//   open={open}
//   onClose={() => setOpen(false)}
//   autoHideDuration={autoHideDuration}
//   toastKey={toastKey}
// >
//   <div className={`${classes["toast"]} ${classes["success-toast"]}`}>
//     <div className={classes["toast-title-container"]}>
//       {/* <img
//         className={classes["toast-icon"]}
//         src={successIcon}
//         alt="success"
//       /> */}
//       <div className={classes["toast-title"]}>{title}</div>
//     </div>
//     <div className={classes["toast-message"]}>{message}</div>
//   </div>
// </Toast>
