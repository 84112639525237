import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomTable from "../../../../shared/CustomTable";
import playicon from "../../../../../assets/icons/Vector.svg";
import pauseicon from "../../../../../assets/icons/Vector.svg";
import "./LeadManagmentCallStatus.scss";
import { BsXOctagonFill } from "react-icons/bs";
import { Modal } from "react-bootstrap";
import ReactAudioPlayer from "react-audio-player";

// Modal.setAppElement("#root");

const LeadManagmentCallHistoryTableList = ({
  pagination,
  onPaginationHandler = () => {},
  tableRow = [],
  // onPlay = () => {},
  onDownload = () => {},
  tableLoaderShow = false,
}) => {
  const [tableRowDataList, setTableRowDataList] = useState([]);

  // -----------testing-------------
  const [audioUrl, setAudioUrl] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioPlayerRef = useRef(null);
  const [showAudioPlayer, setShowAudioPlayer] = useState(false);

  console.log("showAudioPlayer", showAudioPlayer);

  // const onPlay = useCallback((audioPath) => {
  //   console.log("audioPath", audioPath);
  //   setAudioUrl(audioPath);
  //   setIsModalOpen(true); // Open the modal with the audio player
  // }, []);

  const onClose = () => {
    setIsModalOpen(false);
    setAudioUrl("");
  };

  const handlePlay = () => {
    if (audioPlayerRef.current) {
      audioPlayerRef.current.audioEl.current.play();
    }
  };

  const onPlay = () => {
    if (audioPlayerRef.current) {
      if (isPlaying) {
        // Pause the audio
        audioPlayerRef.current.audioEl.current.pause();
      } else {
        // Play the audio
        audioPlayerRef.current.audioEl.current.play();
      }
      setIsPlaying(!isPlaying); // Toggle play/pause state
    }
  };

  const handleAudioPlay = () => {
    // const audio = new Audio(
    //   "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
    // );
    // audio.play();
    setShowAudioPlayer(true);
    console.log("play");
  };

  // const [tableRowData,setTableRowData] = useState()

  const actionOptionRender = useCallback((row) => {
    return (
      <div style={{ display: "flex", gap: "10px" }}>
        <div className="call-history-icon">
          <button
            className="align-middle me-1 cursor-pointer button-icon"
            // onClick={() => onplay(row?.qcrml20_call_recording)}
            onClick={handleAudioPlay}
          >
            {/* <img src={playicon} style={{ height: "1rem" }} alt="Play Icon" /> */}
          </button>
          {true && (
            <audio controls autoPlay>
              {/* <source src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3" /> */}
              <source src={row?.qcrml20_call_recording} />
              Your browser does not support the audio element.
            </audio>
          )}
        </div>
        <hr />
        {/* <div className="call-history-icon">
            <button
              className="align-middle me-1 cursor-pointer button-icon"
              onClick={() => onDownload(row)}
            >
              <i className="bi bi-download" data-feather="pie-chart"></i>
            </button>
          </div> */}
      </div>
    );
  }, []);

  const statusOptionRender = useCallback((row) => {
    return (
      <div style={{ display: "flex", gap: "10px" }}>
        <div
          className=""
          style={{
            color:
              row?.callStatusid === 1
                ? "rgba(198, 33, 33, 1)"
                : row?.callStatusid === 0
                ? "rgba(188, 153, 61, 1)"
                : "rgba(22, 163, 74, 1)",
          }}
        >
          {row?.callStatus}
        </div>
      </div>
    );
  }, []);

  const remarksRenderFun = useCallback((row) => {
    return (
      <div>
        {/* <button
          type="button"
          class="btn btn-secondary"
          data-toggle="tooltip"
          data-placement="top"
          title="Tooltip on top"
        >
          {row?.remarks}
          rerere
        </button> */}
        <span
          className="custom-tooltip"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={row?.remarks}
          style={{
            whiteSpace: "wrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "50px", // Limit the width of the text to show ellipsis
            display: "d-inline-block", // Ensure ellipsis works on inline elements
            cursor: "pointer", // Make it look clickable
          }}
        >
          {row?.remarks?.substring(0, 10)}...{" "}
          {/* Show first 10 characters followed by ellipsis */}
        </span>
      </div>
    );
  }, []);

  const TableColumnDataList = [
    {
      id: 1,
      label: "CRE",
      rowId: "CRE",
      position: "center",
      isRenderItem: false,
    },
    // {
    //   id: 2,
    //   label: "Owner",
    //   rowId: "owner",
    //   position: "center",
    //   isRenderItem: false,
    // },
    {
      id: 3,
      label: "Call Time/Duration",
      rowId: "call_time_duration",
      position: "center",
      isRenderItem: false,
    },
    {
      id: 4,
      label: "Call Status",
      rowId: "Call_status",
      position: "center",
      isRenderItem: false,
    },
    {
      id: 5,
      label: "Next Follow Up",
      rowId: "next_follow_up",
      position: "center",
      isRenderItem: false,
    },
    {
      id: 6,
      label: "Remark",
      rowId: "remarksRender",
      position: "center",
      isRenderItem: true,
    },
    {
      id: 7,
      label: "Recordings",
      rowId: "recordings",
      position: "center",
      isRenderItem: true,
    },
  ];

  useEffect(() => {
    const updatedTableRowDataList = tableRow.map((value) => ({
      ...value,
      recordings: actionOptionRender(value),
      callStatus: statusOptionRender(value),
      remarksRender: remarksRenderFun(value),
    }));
    setTableRowDataList(updatedTableRowDataList);
  }, [actionOptionRender, remarksRenderFun, statusOptionRender, tableRow]);

  return (
    <div>
      <CustomTable
        tableLoaderShow={tableLoaderShow}
        tableColumn={TableColumnDataList}
        tableRow={tableRowDataList}
      />
    </div>
  );
};

export default LeadManagmentCallHistoryTableList;
