import React, { useCallback, useContext, useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "./PolicyDetailsComponent.scss";
import CustomTab from "../../../../shared/CustomTab";
import {
  APIgetLeadDetailForAdmin,
  APIgetLeadDetails,
  APIgetProposalDetailForAdmin,
  APIgetQuotationDetailForAdmin,
  APIgetQuotFromGiib,
  APIgetSoldPolicyDetailForAdmin,
} from "../../../../../api/common";
import GlobalContext from "../../../../../context/GlobalContext";
import { useAsyncError, useNavigate, useSearchParams } from "react-router-dom";
import CustomModal from "../../../../shared/CustomModal";
import OwnershipAndClaim from "./ownerShipAndClaim";
import { useDispatch } from "react-redux";
import { displayToast } from "../../../../shared/toast/toastUtils";
import { useLoader } from "../../../../../context/LoaderProvider";

const TAB_LIST = [
  { id: "details", label: "Details", icon: "bi-card-text" },
  { id: "recommendedplans", label: "Recommended Plans", icon: "bi-card-text" },
];
const PolicyDetailsComponent = ({
  leadDispositionForTel,
  onSendNumber = () => {},
  onSendPolicyNo = () => {},
}) => {
  const [activeTab, setActiveTab] = useState(TAB_LIST[0]);
  const [previousTab, setPreviousTab] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(["Paid Driver Cover"]);
  const [quotationData, setQuotationData] = useState([]);
  const [leadMainData, setLeadMainData] = useState([]);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const [detailsForAdmin, setDetailsForAdmin] = useState([]);
  const [optionValue1, setOptionValue1] = useState("");
  const [optionValue2, setOptionValue2] = useState("");
  const [policyNumber, setPolicyNumber] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const { setShowLoader } = useLoader();
  // const LeadId = searchParams.get("id");
  const leadId = searchParams.get("leadId");
  const productId = searchParams.get("productId");
  const baseParams = searchParams.get("details");
  const { hasAccess } = useContext(GlobalContext);
  const role = localStorage.getItem("role");
  const [tableLoader, setTableLoader] = useState(false);

  const ICLink = quotationData?.map((val) => val?.redirection_url);

  const getAPILeadDetailsHandeler = useCallback(async (id) => {
    const resp = await APIgetLeadDetails(id);
    if (resp?.ok) {
      setLeadMainData(resp?.data?.data?.mainData);
    }
  }, []);

  const getLeadDetailsForAddmin = async (leadId) => {
    const respone = await APIgetLeadDetailForAdmin(leadId);

    if (respone?.ok) {
      setDetailsForAdmin(respone?.data?.data);
    } else {
      setDetailsForAdmin([]);
    }
  };
  // Quotation details send
  const getQuotationDetailsForAddminHandler = async (leadId) => {
    const respone = await APIgetQuotationDetailForAdmin(leadId);
    if (respone?.ok) {
      setDetailsForAdmin(respone?.data?.data);
    } else {
      setDetailsForAdmin([]);
    }
  };

  // Proposal details send
  const getProposalDetailsForAddminhandler = async (leadId) => {
    const response = await APIgetProposalDetailForAdmin(leadId);
    if (response?.ok) {
      setDetailsForAdmin(response?.data?.data);
    } else {
      setDetailsForAdmin([]);
    }
  };

  const getSoldPolicyDetailsForAddminhandler = async (leadId) => {
    const response = await APIgetSoldPolicyDetailForAdmin(leadId);
    if (response?.ok) {
      setDetailsForAdmin(response?.data?.data);
    } else {
      setDetailsForAdmin([]);
    }
  };

  // MOdel box open on recommended Plan

  const handleModelBox = () => {
    setIsModelOpen(true);
  };

  const handleChangeOption = (selectedOption1, selectedOption2) => {
    // setOptionValue1(selectedOption1);
    setOptionValue1(selectedOption1);
    // setOptionValue2(selectedOption2);
    setOptionValue2(selectedOption2);
  };
  const getQuotationHandler = useCallback(async () => {
    // const resp = await APIgetQuotation();
    const payload = {
      // policyNumber: "MAGMA_20231109122738",
      policyNumber: policyNumber,
      is_change_in_ownership: optionValue1,
      is_made_claim: optionValue2,
    };
    // setTableLoader(true);
    setShowLoader(true);
    const resp = await APIgetQuotFromGiib(payload);
    // setTableLoader(false);
    setShowLoader(false);
    if (resp?.ok === true) {
      setQuotationData(resp?.data?.data);
      displayToast({
        type: "success",
        title: resp?.data?.message,
      });
    } else {
      setQuotationData([]);
      displayToast({
        type: "error",
        title: resp?.data?.message,
      });
      setActiveTab(TAB_LIST[0]);
    }
  }, [optionValue1, optionValue2, policyNumber]);

  useEffect(() => {
    if (activeTab?.id === "recommendedplans") {
      handleModelBox();
      // getQuotationHandler();
    }
  }, [activeTab?.id]);

  const handleCheckboxChange = (label) => {
    setSelectedOptions((prev) =>
      prev.includes(label)
        ? prev.filter((item) => item !== label)
        : [...prev, label]
    );
  };
  useEffect(() => {
    if (leadId) {
      getAPILeadDetailsHandeler(leadId);
    }
  }, [leadId, getAPILeadDetailsHandeler]);
  // HAS_UAR_USERS_VIEW_DETAILS
  // useEffect(() => {
  //   if (role === "Telecaller") {
  //     getQuotationHandler();
  //   }
  // }, [getQuotationHandler, role]);

  useEffect(() => {
    if (baseParams === "leadDetails") {
      getLeadDetailsForAddmin(leadId);
    }
  }, [baseParams, leadId]);

  useEffect(() => {
    if (baseParams === "quotation") {
      getQuotationDetailsForAddminHandler(leadId);
    }
  }, [baseParams, leadId]);

  useEffect(() => {
    if (baseParams === "praposal") {
      getProposalDetailsForAddminhandler(leadId);
    }
  }, [baseParams, leadId]);

  useEffect(() => {
    if (baseParams === "sold") {
      getSoldPolicyDetailsForAddminhandler(leadId);
    }
  }, [baseParams, leadId]);

  const firstValue = detailsForAdmin.map(
    (value) => value?.fields.map((val) => val?.value)[0]
  )[0];

  const mobNo = detailsForAdmin.map(
    (val) => val?.fields.map((value) => value?.value)[3]
  )[3];

  // console.log(
  //   "detailsForAdmin",
  //   detailsForAdmin.map((val) => val?.fields.map((value) => value?.value)[3])[3]
  // );

  useEffect(() => {
    setPolicyNumber(firstValue);
    setMobileNumber(mobNo);
  }, [firstValue, mobNo]);
  useEffect(() => {
    onSendNumber(mobileNumber);
    onSendPolicyNo(policyNumber);
  }, [mobileNumber, onSendNumber, onSendPolicyNo, policyNumber]);

  console.log("First value:", firstValue);

  return (
    <div>
      <div className="card pt-3">
        {hasAccess("HAS_LM_VIEW_LEADS_DETAILS_QUOTATION") && (
          <div>
            <CustomTab
              tabList={TAB_LIST}
              onClick={(tab) => {
                setActiveTab(tab);
              }}
              style={{ width: "8rem" }}
              activeTab={activeTab}
            />
            <div className="card-body policydetails-Main-div">
              {activeTab?.id === "details" && (
                <Accordion defaultActiveKey="0" flush>
                  {/* {POLICY_DETAILS?.map((value, index) => { */}
                  {/* {leadDispositionForTel?.map((value, index) => { */}
                  {detailsForAdmin?.map((value, index) => {
                    return (
                      <Accordion.Item
                        eventKey={index}
                        className="custom-accordion"
                      >
                        <Accordion.Header className="custom-accordion-header">
                          {value?.title}
                        </Accordion.Header>
                        <Accordion.Body className="custom-accordion-body ">
                          <div className="policydetails">
                            {value?.fields?.map((data) => {
                              return (
                                <div className="policydetails-text-main-container">
                                  <div className="policydetails-text-container">
                                    <div className="policydetails-text-rightside">
                                      {data?.label}
                                    </div>
                                    <div className="policydetails-text-leftside">
                                      {data?.value}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              )}
              {activeTab?.id === "recommendedplans" &&
                quotationData.map((value) => (
                  <div className="recommended-plan">
                    <div
                      className="card p-3 mb-3 IC1"
                      // onClick={() => setIsModelOpen(true)}
                      onClick={() => window.open(ICLink, "_blank")}
                    >
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div className="d-flex">
                          <img
                            // src="https://dev.mypolicynow.com/assets/front/img/partners-logos/hdfc-logo.png"
                            src={value?.ic_logo}
                            alt="TOICL Logo"
                            style={{ width: "50px" }}
                          />
                          <div className="ms-3">
                            <h5 className="mb-0 title">{value?.ic_code}</h5>
                          </div>
                        </div>
                        <div>
                          <h4 className="price-tag text-primary mb-0">
                            ₹{value?.gross_premium}
                          </h4>
                        </div>
                      </div>
                      {/* <div className="mb-3 mt-3">
                        {(value?.coverList).map((option) => (
                          <div className="form-check" key={option.Id}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={option.Id}
                              checked={selectedOptions.includes(option)}
                              onChange={() => handleCheckboxChange(option)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={option}
                            >
                              {option?.label}
                            </label>
                          </div>
                        ))}
                      </div> */}
                      {/* <button
                        className="btn insurance-portal-btn w-100"
                        onClick={() =>
                          (window.location.href = value?.buttonData?.link)
                        }
                      >
                        {value?.buttonData?.title}
                      </button> */}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
        {hasAccess("HAS_UAR_USERS_VIEW_DETAILS") && (
          <div>
            <div className="d-flex mt-2 headline-details">
              <i class="bi bi-card-text" />
              <div className="ms-2 mb-2">Details</div>
              {/* <span className="header-according-action">
                <CustomButton
                  tooltip={"Edit"}
                  className={"rounded-btn text-dark-teal bg-gray p-0"}
                  onClick={(e) => {
                    e.stopPropagation();
                    // handleToggle();
                    // setOpenInsuForm(true);
                  }}
                >
                  <Icon path={mdiPencilOutline} size={1} />
                </CustomButton>
              </span> */}
            </div>
            <hr />
            <div className="card-body policydetails-Main-div">
              <Accordion defaultActiveKey="0" flush>
                {/* {leadMainData?.map((value, index) => { */}

                {detailsForAdmin?.map((value, index) => {
                  return (
                    <Accordion.Item
                      eventKey={index}
                      className="custom-accordion "
                    >
                      <Accordion.Header className="custom-accordion-header">
                        {value?.title}
                      </Accordion.Header>
                      <Accordion.Body className="custom-accordion-body ">
                        <div className="policydetails">
                          {value?.fields?.map((data) => {
                            return (
                              <div className="policydetails-text-main-container">
                                <div className="policydetails-text-container">
                                  <div className="policydetails-text-rightside">
                                    {data?.label}
                                  </div>
                                  <div className="policydetails-text-leftside">
                                    {data?.value}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div>
          </div>
        )}
      </div>
      <CustomModal
        show={isModelOpen}
        title="Ownership and Claim Details"
        handleClose={() => {
          setIsModelOpen(false);
          setActiveTab(TAB_LIST[0]);
        }}
      >
        <OwnershipAndClaim
          onSave={() => {
            setIsModelOpen(false);
            getQuotationHandler();
          }}
          optionChange={handleChangeOption}
          onCancel={() => {
            setIsModelOpen(false);
            setActiveTab(TAB_LIST[0]);
          }}
        />
      </CustomModal>

      {/* --------------Accordian----------- */}
    </div>
  );
};

export default PolicyDetailsComponent;
