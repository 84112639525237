import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "../pages/NotFound";
import Loadable from "../pages/Loadable";
import VehicalInsurence from "../pages/VehicleInsurence/DashBoardPage";
import ROUTES_NAVIGATION from "./routes";
import TotalLeadComponent from "../components/pages/VehicleInsurenceComponent/TotalLeadComponent";
import InboundLeadComponent from "../components/pages/VehicleInsurenceComponent/InboundLeadComponent";
import CreateCampaignTemplate from "../components/pages/VehicleInsurenceComponent/CampaignTemplateComponent/CreateCampaignTemplate";

const Dashboard = Loadable(
  lazy(() =>
    import(
      "../components/pages/VehicleInsurenceComponent/DashboardComponent/index"
    )
  )
);
// -------------------------Leads-----------------------------
const AllLead = Loadable(
  lazy(() =>
    import(
      "../components/pages/VehicleInsurenceComponent/AllLeadsComponent/index"
    )
  )
);
// const TotalLead = Loadable(lazy(()=>import("../components/pages/VehicleInsurenceComponent/LeadManagementComponent/TotalLeadsComponent/index")))
const LeadDeatils = Loadable(
  lazy(() =>
    import(
      "../components/pages/VehicleInsurenceComponent/LeadManagementComponent/index"
    )
  )
);
const UpdateLeadInfo = Loadable(
  lazy(() =>
    import("../components/pages/VehicleInsurenceComponent/UpdateLeadInfo/index")
  )
);

const QuotationSend = Loadable(
  lazy(() =>
    import(
      "../components/pages/VehicleInsurenceComponent/LeadManagementComponent/QuotationSend/index"
    )
  )
);

const PraposalSend = Loadable(
  lazy(() =>
    import(
      "../components/pages/VehicleInsurenceComponent/LeadManagementComponent/PraposalSend/index"
    )
  )
);
const PraposalLead = Loadable(
  lazy(() => import("../pages/ProposalLeadPage/index"))
);
const QuotationLead = Loadable(
  lazy(() => import("../pages/QuotationLeadPage/index"))
);
// --------------------------------------------
const IDV = Loadable(
  lazy(() =>
    import("../components/pages/VehicleInsurenceComponent/IDVComponent/index")
  )
);
const Discount = Loadable(
  lazy(() =>
    import(
      "../components/pages/VehicleInsurenceComponent/DiscountComponent/index"
    )
  )
);
const BreakinCase = Loadable(
  lazy(() =>
    import(
      "../components/pages/VehicleInsurenceComponent/BreakingCaseComponent/index"
    )
  )
);
const UserAndRole = Loadable(
  lazy(() =>
    import(
      "../components/pages/VehicleInsurenceComponent/UserRolePermissionComponent/index"
    )
  )
);
const TeleCallerPerformance = Loadable(
  lazy(() =>
    import(
      "../components/pages/VehicleInsurenceComponent/TeleCallerPerformancesComponent/index"
    )
  )
);
const SalesCallerPerformance = Loadable(
  lazy(() =>
    import(
      "../components/pages/VehicleInsurenceComponent/SalesManagerPerformaceComponent/index"
    )
  )
);
const DMS = Loadable(
  lazy(() => import("../components/pages/VehicleInsurenceComponent/DMS/index"))
);
const DMSDetails = Loadable(
  lazy(() =>
    import("../components/pages/VehicleInsurenceComponent/DMS/DMSdetails/index")
  )
);
const UploadData = Loadable(
  lazy(() =>
    import("../components/pages/VehicleInsurenceComponent/UploadData/index")
  )
);
const FetchLeads = Loadable(
  lazy(() =>
    import("../components/pages/VehicleInsurenceComponent/FetchLeads/index")
  )
);
const Reports = Loadable(
  lazy(() =>
    import(
      "../components/pages/VehicleInsurenceComponent/ReportComponent/index"
    )
  )
);
const SoldPolicy = Loadable(
  lazy(() =>
    import(
      "../components/pages/VehicleInsurenceComponent/SoldPoliciesComponent/index"
    )
  )
);
const PolicyDoneLead = Loadable(
  lazy(() => import("../pages/PolicyDoneLeadPage/index"))
);
const LostCases = Loadable(
  lazy(() =>
    import("../components/pages/VehicleInsurenceComponent/LostLeads/index")
  )
);
const AditionalFeatures = Loadable(
  lazy(() =>
    import(
      "../components/pages/VehicleInsurenceComponent/AdditionalFeaturesComponent/index"
    )
  )
);
const AdditionalFeaturesUploadNCData = Loadable(
  lazy(() =>
    import(
      "../components/pages/VehicleInsurenceComponent/AdditionalFeaturesComponent/AdditionalFeatureUploadNCData/index"
    )
  )
);

const BulkCampaign = Loadable(
  lazy(() =>
    import("../components/pages/VehicleInsurenceComponent/BulkCampaign/index")
  )
);

const LeadSource = Loadable(
  lazy(() =>
    import(
      "../components/pages/VehicleInsurenceComponent/MasterData/LeadSource/index"
    )
  )
);

const CampaignLeadData = Loadable(
  lazy(() =>
    import(
      "../components/pages/VehicleInsurenceComponent/CampaignLeadComponent/index"
    )
  )
);

const CampaignCallHistory = Loadable(
  lazy(() =>
    import(
      "../components/pages/VehicleInsurenceComponent/CampaignCallHistory/index"
    )
  )
);

const CampaignTemplet = Loadable(
  lazy(() => {
    return import(
      "../components/pages/VehicleInsurenceComponent/CampaignTemplateComponent/index"
    );
  })
);
// -------------auditors-----

const AuditorsLeads = Loadable(
  lazy(() => import("../pages/AuditorsLeadsPage/index"))
);
const AuditorsLeadsDetails = Loadable(
  lazy(() => import("../pages/AuditorsLeadsDetailsPage/index"))
);
const Settings = Loadable(
  lazy(() => import("../pages/VehicleInsurence/SettingsPage/index"))
);
//------------salse manager-------
const MyTeamsUser = Loadable(lazy(() => import("../pages/MyTeams/index")));

// const Inbound = Loadable(
//   lazy(() => {
//     import(
//       "../components/pages/VehicleInsurenceComponent/InboundLeadComponent/index"
//     )
//   })
// );
const TrainingMarketing = Loadable(
  lazy(() => import("../pages/TrainingMarketingPage/index"))
);
const ActionableLeadsPage = Loadable(
  lazy(() => import("../pages/ActionableLeadsPage/index"))
);
const ActionableLeadDetailsPage = Loadable(
  lazy(() => import("../pages/ActionableLeadDetailsPage/index"))
);
const RenewalNoticesPage = Loadable(
  lazy(() => import("../pages/RenewalNoticesPage/index"))
);
const ChatPage = Loadable(lazy(() => import("../pages/ChatPage/index")));
const VehicleInsurenceRoute = () => {
  const navigatReplacableRoute = (nav) => {
    return nav.replace(ROUTES_NAVIGATION.VEHICLE_INSURENCE + "/", "");
  };

  return (
    <Routes>
      <Route path={`/*`} element={<VehicalInsurence />}>
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_DASHBOARD
          )}
          element={<Dashboard />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_ALL_LEADS
          )}
          element={<AllLead />}
        />
        {/* <Route path={navigatReplacableRoute(ROUTES_NAVIGATION.VEHICLE_INSURENCE_ALL_LEADS)} element={<TotalLead/>}/> */}
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_LEAD_DETAILS
          )}
          element={<LeadDeatils />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_UPDATE_LEAD_INFO
          )}
          element={<UpdateLeadInfo />}
        />

        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_QUOTATION_SEND
          )}
          element={<QuotationSend />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_PRAPOSAL_SEND
          )}
          element={<PraposalSend />}
        />
        <Route
          path={navigatReplacableRoute(ROUTES_NAVIGATION.VEHICLE_INSURENCE_IDV)}
          element={<IDV />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_DISCOUNT
          )}
          element={<Discount />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_BREAKING_CASE
          )}
          element={<BreakinCase />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_USER_AND_ROLE
          )}
          element={<UserAndRole />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_TELE_CALLER_PERFORMANCE
          )}
          element={<TeleCallerPerformance />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_SALES_MANAGER_PERFORMANCE
          )}
          element={<SalesCallerPerformance />}
        />
        <Route
          path={navigatReplacableRoute(ROUTES_NAVIGATION.VEHICLE_INSURENCE_DMS)}
          element={<DMS />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_DMS_DETAILS
          )}
          element={<DMSDetails />}
        />

        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_UPLOAD_DATA
          )}
          element={<UploadData />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_FETCH_LEADS
          )}
          element={<FetchLeads />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_REPORTS
          )}
          element={<Reports />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_SOLD_POLICY
          )}
          element={<SoldPolicy />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_LOST_CASES
          )}
          element={<LostCases />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_ADDITIONAL_FEATURS
          )}
          element={<AditionalFeatures />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_ADDITIONAL_FEATURS_UPLOAD_NC_DATA
          )}
          element={<AdditionalFeaturesUploadNCData />}
        />

        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_CAMPAIGN_TEMPLATE
          )}
          element={<CampaignTemplet />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_CREATE_CAMPAIGN_TEMPLATE
          )}
          element={<CreateCampaignTemplate />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_MASTER_LEAD_SOURCE
          )}
          element={<LeadSource />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_SETTINGS
          )}
          element={<Settings />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_AUDITORS_LEADS
          )}
          element={<AuditorsLeads />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_CAMPAIGN_LEAD
          )}
          element={<CampaignLeadData />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_BULK_CAMPAIGN
          )}
          element={<BulkCampaign />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_AUDITORS_LEADS_DETAILS
          )}
          element={<AuditorsLeadsDetails />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_TOTALLEAD
          )}
          element={<TotalLeadComponent />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_INBOUND_LEAD
          )}
          element={<InboundLeadComponent />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_SALES_MANAGER_MY_TEAMS
          )}
          element={<MyTeamsUser />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_CAMPAIGN_LEAD_MANAGEMENT
          )}
          element={<CampaignCallHistory />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_POLICY_DONE_LEAD
          )}
          element={<PolicyDoneLead />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_PROPOSAL_LEAD
          )}
          element={<PraposalLead />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_QUOTATION_LEAD
          )}
          element={<QuotationLead />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_TRAINING_MARKETING
          )}
          element={<TrainingMarketing />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_RENEWAL_NOTICES
          )}
          element={<RenewalNoticesPage />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_ACTIONABLE_LEAD
          )}
          element={<ActionableLeadsPage />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_ACTIONABLE_LEAD_DETAILS
          )}
          element={<ActionableLeadDetailsPage />}
        />
        <Route
          path={navigatReplacableRoute(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_CHAT
          )}
          element={<ChatPage />}
        />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default VehicleInsurenceRoute;
