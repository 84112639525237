export const INBOUND_LIST_DATA = [
    {
      id: 0,
      leadID: "124tyhgfrdsd",
      cunstomer: "C.L Anwar",
      telecaller: "XYZ",
      policyType: "New",
      endDate: "-",
      registrationNumber: "12xxx23",
      engineNumber: "12xxx45",
      chasisNumber: "123xx34",
      email: "-",
      mobile: "-",
      productName: "SOD",
      disposition: "-",
      subDisp: "-",
      status: "New",
    },
    {
        id: 1,
        leadID: "124tyhgfrdsd",
        cunstomer: "C.L Anwar",
        telecaller: "XYZ",
        policyType: "New",
        endDate: "-",
        registrationNumber: "12xxx23",
        engineNumber: "12xxx45",
        chasisNumber: "123xx34",
        email: "-",
        mobile: "-",
        productName: "SOD",
        disposition: "-",
        subDisp: "-",
        status: "Connected",
      },
    {
        id: 2,
        leadID: "124tyhgfrdsd",
        cunstomer: "C.L Anwar",
        telecaller: "XYZ",
        policyType: "New",
        endDate: "-",
        registrationNumber: "12xxx23",
        engineNumber: "12xxx45",
        chasisNumber: "123xx34",
        email: "-",
        mobile: "-",
        productName: "SOD",
        disposition: "-",
        subDisp: "-",
        status: "New",
      },
    {
        id: 3,
        leadID: "124tyhgfrdsd",
        cunstomer: "C.L Anwar",
        telecaller: "XYZ",
        policyType: "New",
        endDate: "-",
        registrationNumber: "12xxx23",
        engineNumber: "12xxx45",
        chasisNumber: "123xx34",
        email: "-",
        mobile: "-",
        productName: "SOD",
        disposition: "-",
        subDisp: "-",
        status: "New",
      },
    {
        id: 4,
        leadID: "124tyhgfrdsd",
        cunstomer: "C.L Anwar",
        telecaller: "XYZ",
        policyType: "New",
        endDate: "-",
        registrationNumber: "12xxx23",
        engineNumber: "12xxx45",
        chasisNumber: "123xx34",
        email: "-",
        mobile: "-",
        productName: "SOD",
        disposition: "-",
        subDisp: "-",
        status: "New",
      },
    {
        id: 5,
        leadID: "124tyhgfrdsd",
        cunstomer: "C.L Anwar",
        telecaller: "XYZ",
        policyType: "New",
        endDate: "-",
        registrationNumber: "12xxx23",
        engineNumber: "12xxx45",
        chasisNumber: "123xx34",
        email: "-",
        mobile: "-",
        productName: "SOD",
        disposition: "-",
        subDisp: "-",
        status: "New",
      },
    {
        id: 6,
        leadID: "124tyhgfrdsd",
        cunstomer: "C.L Anwar",
        telecaller: "XYZ",
        policyType: "New",
        endDate: "-",
        registrationNumber: "12xxx23",
        engineNumber: "12xxx45",
        chasisNumber: "123xx34",
        email: "-",
        mobile: "-",
        productName: "SOD",
        disposition: "-",
        subDisp: "-",
        status: "Connected",
      },
    {
        id: 7,
        leadID: "124tyhgfrdsd",
        cunstomer: "C.L Anwar",
        telecaller: "XYZ",
        policyType: "New",
        endDate: "-",
        registrationNumber: "12xxx23",
        engineNumber: "12xxx45",
        chasisNumber: "123xx34",
        email: "-",
        mobile: "-",
        productName: "SOD",
        disposition: "-",
        subDisp: "-",
        status: "Connected",
      },
    {
        id: 8,
        leadID: "124tyhgfrdsd",
        cunstomer: "C.L Anwar",
        telecaller: "XYZ",
        policyType: "New",
        endDate: "-",
        registrationNumber: "12xxx23",
        engineNumber: "12xxx45",
        chasisNumber: "123xx34",
        email: "-",
        mobile: "-",
        productName: "SOD",
        disposition: "-",
        subDisp: "-",
        status: "Connected",
      },
]