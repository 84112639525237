// import React from 'react';

// function CustomRadioButton({ id, label, checked, onChange }) {
//   return (
//     <div className="form-check">
//       <input
//         className="form-check-input"
//         type="radio"
//         id={id}
//         checked={checked}
//         onChange={onChange}
//       />
//       <label className="form-check-label" htmlFor={id}>
//         {label}
//       </label>
//     </div>
//   );
// }

// export default CustomRadioButton;

// import React, { useState, useEffect } from "react";

// import "./CustomRadio.scss";

// function CustomRadioButton({
//   // bringMyValue,
//   id,
//   label,
//   name,
//   register,
//   radioInputvalue,
//   value,
//   errors,
//   setValue,
//   defaultValue = {
//     id: 1,
//     label: "Yes",
//     value: "Yes",
//   }, // Use defaultValue to set the initial selected value
//   onChange = () => {},
//   className,
//   classNameError,
//   options = [
//     {
//       id: 1,
//       label: "Yes",
//       value: "Yes",
//     },
//     {
//       id: 0,
//       label: "No",
//       value: "No",
//     },
//   ],
// }) {
//   const [selectedValue, setSelectedValue] = useState(defaultValue?.value);

//   // const handleChange = (data, event) => {
//   //   const newValue = event.target.value;
//   //   setSelectedValue(newValue);
//   //   onChange(data, newValue);
//   // };

//   // useEffect(() => {
//   //   if (defaultValue?.value) {
//   //     setSelectedValue(defaultValue?.value);
//   //   }
//   // }, [defaultValue?.value]);

//   const handleChange = (e) => {
//     const newValue = e.target.value;
//     setSelectedValue(newValue);
//     setValue(name, newValue, { shouldValidate: true });
//     radioInputvalue(e.target.value);
//   };

//   useEffect(() => {
//     register(name, { required: "Status is required" });
//     console.log("selectedValue -->", selectedValue);
//   }, [register, name, selectedValue]);

//   return (
//     <>
//       <div className={`form-check d-flex gap-5 ${className}`}>
//         {options?.map((option, index) => {
//           return (
//             <div key={option?.id}>
//               <input
//                 className="form-check-input"
//                 type="radio"
//                 name={name}
//                 id={`${name}-${option?.id}`}
//                 value={option?.value}
//                 checked={selectedValue === option?.value}
//                 // onChange={(e) => handleChange(option, e)}
//                 onChange={handleChange}
//               />
//               <label
//                 className="form-check-label text-nowrap"
//                 htmlFor={`${name}-${option?.id}`}
//               >
//                 {option?.label}
//               </label>
//             </div>
//           );
//         })}
//       </div>
//       {errors[name] && (
//         <p
//           className={`text-danger error-msg-status error-msg-auditor ${className}`}
//         >
//           {errors[name].message}
//         </p>
//       )}
//     </>
//   );
// }

// export default CustomRadioButton;

import React, { useState, useEffect } from "react";

function CustomRadioButton({
  id,
  label,
  name,
  defaultValue = {
    // id: 1,
    // label: "Yes",
    // value: "Yes",
  }, // Use defaultValue to set the initial selected value
  onChange = () => {},
  className,
  options = [
    {
      id: 1,
      label: "Yes",
      value: "yes",
    },
    {
      id: 0,
      label: "No",
      value: "no",
    },
  ],
  required = false,
  radioInputvalue,
}) {
  const [selectedValue, setSelectedValue] = useState(defaultValue?.value);
  // const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (data, event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    onChange(data, newValue);
  };

  // useEffect(() => {
  //   if (defaultValue?.value) {
  //     setSelectedValue(defaultValue?.value);
  //   }
  // }, [defaultValue?.value]);
  // const handleChange = (e) => {
  //   const newValue = e.target.value;
  //   setSelectedValue(newValue);
  //   setValue(name, newValue, { shouldValidate: true });
  //   radioInputvalue(e.target.value);
  // };

  // useEffect(() => {
  //   register(name, { required: "Status is required" });
  //   console.log("selectedValue -->", selectedValue);
  // }, [register, name, selectedValue]);
  return (
    <>
      <div className={` form-check d-flex gap-5 ${className} `}>
        {options?.map((option, index) => {
          return (
            <div key={option?.id}>
              <input
                className="form-check-input"
                type="radio"
                name={name}
                id={`${name}-${option?.id}`}
                onChange={(e) => handleChange(option, e)}
                value={option?.value}
                checked={selectedValue === option?.value}
                required={required}
              />
              <label
                className="form-check-label"
                htmlFor={`${name}-${option?.id}`}
              >
                {option?.label}
              </label>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default CustomRadioButton;
