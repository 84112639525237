import React, { useCallback, useEffect, useState } from "react";
import CustomDropdown from "../../../../shared/CustomDropdown";
import CustomDateInput from "../../../../shared/CustomDateInput";
import "./CallDetails.scss";
import CustomButton from "../../../../shared/CustomButton";
import {
  addDisposition,
  APIgetDispositionDropdown,
} from "../../../../../api/common";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import moment from "moment/moment";

const CallDetailsComponent = ({
  onTrigger = () => {},
  handleSaveClick = () => {},
}) => {
  // const [remark, setRemark] = useState("");
  const [dispositoinOption, setDispositionOption] = useState();
  const [searchParams] = useSearchParams();
  const leadId = searchParams.get("leadId");
  const ProductId = searchParams.get("productId");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [formData, setFormData] = useState({
    dispositionId: null,
    nextFollowUpDate: null,
    nextFollowUpTime: null,
  });

  const onSubmit = useCallback(
    async (data) => {
      setFormData(data);
      const payload = {
        ...data,
        nextFollowUpDate: moment(data.nextFollowUpDate).format("DD-MM-YYYY"),
        nextFollowUpTime: moment(data.nextFollowUpTime).format("HH:mm:ss"),
        leadMasterId: leadId,
        leadProductId: ProductId,
      };
      // Remove any undefined fields
      Object.keys(payload).forEach((key) => {
        if (payload[key] === undefined) {
          delete payload[key];
        }
      });
      try {
        const result = await addDisposition(payload);
        console.log("Result:", result);
        onTrigger();
        handleSaveClick();
      } catch (error) {
        console.error("Error submitting data:", error);
      }
    },
    [leadId, ProductId, onTrigger, handleSaveClick]
  );

  const getDispositionDropdownOption = useCallback(async () => {
    const resp = await APIgetDispositionDropdown();
    if (resp?.ok) {
      setDispositionOption(resp?.data?.data || []);
    } else {
      setDispositionOption([]);
    }
  }, []);
  const handleFormUpdates = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setValue(name, value);
  };
  const handleDateChange = (date, name) => {
    handleFormUpdates({
      target: {
        name: "nextFollowUpDate",
        value: date,
      },
    });
    setFormData((prev) => ({
      ...prev,
      [name]: date,
    }));
  };

  const handleTimeChange = (date) => {
    handleFormUpdates({
      target: {
        name: "nextFollowUpTime",
        value: date,
      },
    });
  };
  useEffect(() => {
    getDispositionDropdownOption();
  }, [getDispositionDropdownOption]);
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mainContainer">
      <div className="row align-item-center">
        <div className="col-4 date-input">
          {/* <label>Call Disposition</label> */}
          <CustomDropdown
            label={"Call Disposition"}
            options={dispositoinOption}
            name={"dispositionId"}
            placeholder="Select Call Disposition"
            value={formData["dispositionId"]}
            optionLabel={"label"}
            optionValue={"id"}
            register={register("dispositionId", {
              required: "Call Disposition is required",
            })}
            error={errors.callDisposition}
            onChange={handleFormUpdates}
          />
        </div>
        <div className="col-3 date-input">
          <label>Next Follow up Date</label>
          <CustomDateInput
            isDateRange={false}
            selected={formData["nextFollowUpDate"]}
            name={"nextFollowUpDate"}
            register={register("nextFollowUpDate")}
            onChange={(date) => handleDateChange(date, "nextFollowUpDate")}
          />
        </div>
        <div className="col-3">
          <label>Next Follow up Time</label>
          <CustomDateInput
            dateFormat="HH.MM.SS"
            isDateRange={false}
            showTimeSelect
            showTimeSelectOnly
            selected={formData["nextFollowUpTime"]}
            name={"nextFollowUpTime"}
            register={register("nextFollowUpTime")}
            onChange={handleTimeChange}
          />
        </div>
      </div>
      <div className="form-group mt-3">
        <div>Remark</div>
        <textarea
          id="remarks"
          name="remarks"
          className="form-control remark-form mt-2"
          rows="2"
          value={formData["remarks"]}
          onChange={handleFormUpdates}
          typeof={"text"}
          placeholder="Text here...."
        ></textarea>
      </div>
      <div className="d-flex justify-content-end btn-save">
        <CustomButton className="save-btn fw-bold me-2 mt-3" type="submit">
          Save
        </CustomButton>
      </div>
    </form>
  );
};

export default CallDetailsComponent;
