import React, { useCallback, useEffect, useState } from "react";
import CustomButton from "../../../shared/CustomButton";
import "./LeadManagement.scss";
import { useForm, Controller } from "react-hook-form";

import CustomInput from "../../../shared/CustomInput";
import { useSearchParams } from "react-router-dom";
import { APISendEmail, APISendSMS } from "../../../../api/common";
import { displayToast } from "../../../shared/toast/toastUtils";
const SelectMassageTypeComponent = ({
  onCancel = () => {},
  onSave = () => {},
  leadMobNo,
  leadPolicyNo,
}) => {
  const [messageType, setMessageType] = useState("renewal");
  const [smsChecked, setSmsChecked] = useState(false);
  // const [whatsappChecked, setWhatsappChecked] = useState(true);
  const [emailChecked, setEmailChecked] = useState(false);
  const [mobile1, setMobile1] = useState(null);
  const [mobile2, setMobile2] = useState("");
  const [email, setEmail] = useState("");
  const { control } = useForm({});

  const [searchParams] = useSearchParams();

  const leadId = searchParams.get("leadId");
  const productId = searchParams.get("productId");

  console.log("mobile1", mobile1);
  console.log("productId", productId);
  console.log("##", leadMobNo);
  console.log("###", leadPolicyNo);

  const handleSubmit = useCallback(async () => {
    if (smsChecked) {
      const payload = {
        mobile_no: leadMobNo,
        policy_number: leadPolicyNo,
      };
      const resp = await APISendSMS(payload);
      console.log("resp", resp);
      if (resp?.ok) {
        onSave();
        displayToast({
          type: "success",
          title: resp?.data?.message,
        });
      } else {
        displayToast({
          type: "error",
          title: resp?.data?.message,
        });
      }
    }
    if (emailChecked) {
      const payload = {
        email: email,
      };
      const resp = await APISendEmail(payload, leadId);
      console.log("resp", resp?.data?.message);
      if (resp?.ok) {
        onSave();
        displayToast({
          type: "success",
          title: resp?.data?.message,
        });
      } else {
        displayToast({
          type: "error",
          title: resp?.data?.message,
        });
      }
    }
  }, [
    email,
    emailChecked,
    leadId,
    leadMobNo,
    leadPolicyNo,
    onSave,
    smsChecked,
  ]);

  // useEffect(() => {
  //   handleSubmit();
  // }, [handleSubmit]);

  return (
    <div className="container select-massage">
      <div className="form-check form-check-inline mt-2">
        <input
          className="form-check-input"
          type="radio"
          name="messageType"
          id="sendRenewal"
          // value="renewal"
          value={mobile1}
          checked={messageType === "renewal"}
          onChange={() => setMessageType("renewal")}
        />
        <label className="form-check-label" htmlFor="sendRenewal">
          Send Renewal Notice
        </label>
      </div>
      {/* <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="radio"
          name="messageType"
          id="sendNilEndorsement"
          value="nilEndorsement"
          checked={messageType === "nilEndorsement"}
          onChange={() => setMessageType("nilEndorsement")}
        />
        <label className="form-check-label" htmlFor="sendNilEndorsement">
          Send Nil Endorsement Link
        </label>
      </div> */}

      <div className="row mt-3 middle-content">
        {/* <div className="col-md-6 second-content p-3 me-4 ms-2"> */}
        <div className="col-6 second-content p-3 me-4 ms-2">
          <div className="check-box d-flex justify-content-center align-items-center p-3">
            <input
              className="form-check-input"
              type="checkbox"
              id="smsCheckbox"
              checked={smsChecked}
              onChange={() => setSmsChecked(!smsChecked)}
            />
            <label className="custom-control-label" htmlFor="smsCheckbox">
              SMS
            </label>
          </div>
          {smsChecked && (
            <div>
              <input
                type="text"
                className="form-control my-2"
                placeholder="Mobile 1"
                value={leadMobNo}
                onChange={(e) => setMobile1(e.target.value)}
              />
              <input
                type="text"
                className="form-control my-2"
                placeholder="Mobile 2"
                value={mobile2}
                onChange={(e) => setMobile2(e.target.value)}
              />
            </div>
          )}
        </div>

        {/* <div className="col-md-4 second-content p-3 ">
          <div className="check-box d-flex justify-content-center align-items-center p-3">
            <input
              className="form-check-input"
              type="checkbox"
              id="whatsappCheckbox"
              checked={whatsappChecked}
              onChange={() => setWhatsappChecked(!whatsappChecked)}
            />
            <label className="custom-control-label" htmlFor="whatsappCheckbox">
              WhatsApp
            </label>
          </div>
          {whatsappChecked && (
            <div>
              <input
                type="text"
                className="form-control my-2"
                placeholder="Mobile 1"
                value={mobile1}
                onChange={(e) => setMobile1(e.target.value)}
              />
              <input
                type="text"
                className="form-control my-2"
                placeholder="Mobile 2"
                value={mobile2}
                onChange={(e) => {
                  setMobile2(e.target.value);
                  console.log(e.target.target);
                }}
              />
            </div>
          )}
        </div> */}

        <div
          // className="col-md-6 second-content p-3 me-2 ms-4"
          className="col-6 second-content p-3 me-2 ms-4"
          style={{
            border: "1px solid rgba(220, 211, 238, 1)",
            borderRadius: "10px",
            width: "230px",
          }}
        >
          <div className="check-box d-flex justify-content-center align-items-center p-3">
            <input
              className="form-check-input"
              type="checkbox"
              id="emailCheckbox"
              checked={emailChecked}
              onChange={() => setEmailChecked(!emailChecked)}
            />
            <label className="custom-control-label" htmlFor="emailCheckbox">
              Email
            </label>
          </div>
          {emailChecked && (
            <div>
              {/* <input
                type="email"
                className="form-control my-2"
                placeholder="Email Address"
              /> */}
              <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
                <Controller
                  control={control}
                  name={"leadEmail"}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required to be filled*",
                    },
                    pattern: {
                      value:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in|org|co|net)$/,
                      message: "Invalid Email Id format",
                    },
                  }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <div className="custom-input-wrapper">
                      <label htmlFor="customInput" className="form-label">
                        {"Email "}
                      </label>
                      <span className="text-danger">*</span>
                      <div className="input-with-prefix">
                        <input
                          type={"text"}
                          className="form-control form-control-lg"
                          placeholder={"Enter Email"}
                          value={value}
                          onChange={(e) => {
                            // handleFormUpdate(value);
                            onChange(e);
                            setEmail(e.target.value);
                          }}
                          onBlur={onBlur}
                        />
                      </div>

                      {error && (
                        <div className="error-message-input">
                          {error.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mt-3">
        <p>
          Dear customer, your payment for{" "}
          <strong>Private Car Package Policy {leadPolicyNo}</strong> is due for
          renewal on <strong>31-04-2024</strong>. Kindly review your proposal by
          visiting the link{" "}
          <a href="https://quantique.ai">Quantique Metadata Pvt Ltd</a> or call
          on toll free number 1803-6874-5254. Kindly Ignore if done. Thank You
        </p>
        <p>
          Team <br />
          (Quantique Metadata Pvt. Ltd.)
        </p>
      </div>
      <div className="form-button-main d-flex justify-content-end mt-3">
        <CustomButton
          className="view-template-btn mr-2 me-2 text-center d-flex align-items-center justify-content-center"
          onClick={onCancel}
        >
          <span className="view-btn ">Cancel</span>
        </CustomButton>
        <CustomButton className="report-download-btn mr-2 text-center d-flex align-items-center justify-content-center">
          <span className="download-btn " onClick={handleSubmit}>
            Save
          </span>
        </CustomButton>
      </div>
    </div>
  );
};

export default SelectMassageTypeComponent;
