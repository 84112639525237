import ROUTES_NAVIGATION from "../../routes/routes";
import { red } from "@mui/material/colors";
import {
  mdiAccountEdit,
  mdiAccountFilterOutline,
  mdiAccountMultiple,
  mdiAccountOffOutline,
  mdiAccountPlus,
  mdiBullhornOutline,
  mdiCash,
  mdiCheckboxMarkedCirclePlusOutline,
  mdiDatabase,
  mdiFileAccount,
  mdiFileChart,
  mdiFileChartOutline,
  mdiFileDocumentMultipleOutline,
  mdiHelpCircleOutline,
  mdiMessageAlertOutline,
  mdiPercentCircle,
  mdiPercentCircleOutline,
  mdiStackOverflow,
  mdiSwapHorizontal,
  mdiTextBoxSearchOutline,
  mdiTextSearch,
  mdiUploadCircleOutline,
  mdiViewDashboardOutline,
} from "@mdi/js";
export const DrawerNavigation = [
  {
    id: "dashboard",
    parent: "dashboard",
    path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_DASHBOARD,
    key: "HAS_DASHBOARD",
    sidebarProps: {
      displayText: "Dashboard",
      icons: mdiViewDashboardOutline,
      // icon: "bi bi-columns-gap",
    },
    // state: "master",
    child: [],
  },
  {
    id: "masterData",
    parent: "masterData",
    key: "HAS_MASTER_DATA",
    sidebarProps: {
      displayText: "Master Data",
      icons: mdiDatabase,
      // icon: "bi bi-database-fill",
      colors: red,
    },
    // state: "manageleads",
    child: [
      {
        id: "leadsource",
        parent: "masterData",
        index: true,
        path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_MASTER_LEAD_SOURCE,
        key: "HAS_MASTER_SOURCE",
        element: "",
        // state: "manageleads.allleads",
        sidebarProps: {
          displayText: "Lead Source",
          icons: mdiTextBoxSearchOutline,
        },
      },
    ],
  },
  // {
  //   // Vertical line between "Dashboard" and "Manage Leads"
  //   id: "divider",
  //   isDivider: true,
  // },
  {
    id: "manageleads",
    parent: "manageleads",
    key: "HAS_LEAD_MANAGEMENT",
    sidebarProps: {
      displayText: "Manage Leads",
      icons: mdiAccountFilterOutline,
      // icon: "bi-person-check",
    },
    // state: "manageleads",
    child: [
      {
        id: "alllead",
        parent: "manageleads",
        index: true,
        path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_ALL_LEADS,
        key: "HAS_LM_ALL_LEADS",
        element: "",
        // state: "manageleads.allleads",
        sidebarProps: {
          displayText: "All Lead",
          icons: mdiAccountMultiple,
          icon: "bi bi-people",
        },
      },
      {
        id: "updateLeadInfo",
        parent: "manageleads",
        index: true,
        path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_UPDATE_LEAD_INFO,
        key: "HAS_LM_UPDATE_LEADS_INFO",
        element: "",
        // state: "manageleads.allleads",
        sidebarProps: {
          displayText: "Update Lead Info",
          icons: mdiAccountEdit,
          icon: "bi bi-people",
        },
      },
      {
        id: "quotationSend",
        parent: "manageleads",
        index: true,
        path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_QUOTATION_SEND,
        key: "HAS_SENT_QUATATION",
        element: "",
        // state: "manageleads.couponcodes",
        sidebarProps: {
          displayText: "Quotation Sent",
          icons: mdiAccountPlus,
          icon: "bi bi-check2-circle",
        },
      },
      {
        id: "praposalSend",
        parent: "manageleads",
        index: true,
        path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_PRAPOSAL_SEND,
        key: "HAS_SENT_PROPOSAL",
        element: "",
        // state: "manageleads.couponcodes",
        sidebarProps: {
          displayText: "Proposal Sent",
          icons: mdiFileAccount,
          icon: "bi bi-file-earmark-person",
        },
      },
      {
        id: "soldPolicies",
        parent: "manageleads",
        index: true,
        path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_SOLD_POLICY,
        key: "HAS_SOLD_POLICIES",
        element: "",
        // state: "manageleads.couponcodes",
        sidebarProps: {
          displayText: "Sold Policies",
          icons: mdiCheckboxMarkedCirclePlusOutline,
          icon: "bi bi-cash",
        },
      },
      {
        id: "lostLead",
        parent: "manageleads",
        index: true,
        path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_LOST_CASES,
        key: "HAS_LOST_CASES",
        element: "",
        // state: "manageleads.couponcodes",
        sidebarProps: {
          displayText: "Lost Leads",
          icons: mdiMessageAlertOutline,
          // icon: "bi bi-exclamation-square",
        },
      },
      // {
      //   id: "lostLead",
      //   parent: "manageleads",
      //   index: true,
      //   path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_LOST_CASES,
      //   key: "HAS_LOST_CASES",
      //   element: "",
      //   // state: "manageleads.couponcodes",
      //   sidebarProps: {
      //     displayText: "Lost Leads",
      //     icons: mdiMessageAlertOutline,
      //     // icon: "bi bi-exclamation-square",
      //   },
      // },
    ],
  },
  {
    id: "leadDaviation",
    parent: "leadDaviation",
    key: "HAS_LEAD_DEVIATION",
    sidebarProps: {
      displayText: "Leads Deviation",
      icons: mdiSwapHorizontal,
      // icon: "bi bi-arrow-left-right",
    },
    // state: "manageleads",
    child: [
      {
        id: "idv",
        parent: "leadDaviation",
        index: true,
        path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_IDV,
        key: "HAS_IDV",
        element: "",
        // state: "manageleads.couponcodes",
        sidebarProps: {
          displayText: "IDV",
          icons: mdiCash,
          icon: "bi-cash",
        },
      },
      {
        id: "discount",
        parent: "leadDaviation",
        index: true,
        path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_DISCOUNT,
        key: "HAS_DISCOUNT",
        element: "",
        // state: "manageleads.couponcodes",
        sidebarProps: {
          displayText: "Discount",
          icons: mdiPercentCircleOutline,
          icon: "bi bi-bag-dash",
        },
      },
      {
        id: "breakincase",
        parent: "leadDaviation",
        index: true,
        path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_BREAKING_CASE,
        key: "HAS_BREAK_IN",
        element: "",
        // state: "manageleads.couponcodes",
        sidebarProps: {
          displayText: "Break In",
          icons: mdiAccountOffOutline,
          icon: "bi-person-x",
        },
      },
    ],
  },
  //   {
  //     id: "master",
  //     parent: "master",
  //     path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_MASTER_DATA,
  //     sidebarProps: {
  //       displayText: "Master Data",
  //  icons: mdiTextSearch,//
  // icon: "bi-database",
  //     },
  //     // state: "master",
  //     child: [],
  //   },
  {
    id: "users",
    parent: "users",
    path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_USER_AND_ROLE,
    key: "HAS_USER_ROLE",
    sidebarProps: {
      displayText: "User & Role",
      icons: mdiAccountPlus,
      icon: "bi-person-plus",
    },
    // state: "users",
    child: [],
  },
  {
    id: "myteams",
    parent: "myTeams",
    key: "HAS_MY_TEAMS",
    path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_SALES_MANAGER_MY_TEAMS,
    sidebarProps: {
      displayText: "My Teams",
      icons: mdiTextSearch,
      icon: "bi bi-person-plus",
    },
  },
  {
    id: "documents",
    parent: "documents",
    path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_DMS,
    key: "HAS_DMS",
    sidebarProps: {
      displayText: "Document",
      icons: mdiFileDocumentMultipleOutline,
      icon: "bi-card-text",
    },
    // state: "store",
    child: [],
  },
  {
    id: "upload",
    parent: "upload",
    path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_UPLOAD_DATA,
    key: "HAS_UPLOAD_DATA",
    sidebarProps: {
      displayText: "Upload Data",
      // icons: mdiTextSearch, //
      icon: "bi-upload",
      icons: mdiUploadCircleOutline,
      // icon: "bi bi-arrow-up-circle",
    },
    // state: "site",
    child: [],
  },
  {
    id: "fetch",
    parent: "fetch",
    path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_FETCH_LEADS,
    key: "HAS_UPLOAD_DATA",
    sidebarProps: {
      displayText: "Fetch Leads",
      // icons: mdiTextSearch, //
      icon: "bi-upload",
      icons: mdiUploadCircleOutline,
      // icon: "bi bi-arrow-up-circle",
    },
    // state: "site",
    child: [],
  },

  // {
  //   id: "order",
  //   parent: "order",
  //   path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_REPORTS,
  //   key: "HAS_REPORT",
  //   sidebarProps: {
  //     displayText: "Reports",
  //     icons: mdiFileChart,
  //     icon: "bi bi-columns-gap",
  //   },
  //   // state: "order",
  //   child: [],
  // },

  // {
  //   id: "settings",
  //   parent: "settings",
  //   path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_SOLD_POLICY,
  //   sidebarProps: {
  //     displayText: "Sold Policies",
  //  icons: mdiTextSearch,//
  // icon: "bi-file-earmark-text",
  //   },
  //   // state: "settings",
  //   child: [],
  // },
  // {
  //   id: "logs",
  //   parent: "logs",
  //   path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_LOST_CASES,
  //   sidebarProps: {
  //     displayText: "Lost Cases",
  //  icons: mdiTextSearch,//
  // icon: "bi-file-earmark",
  //   },
  //   // state: "logs",
  //   child: [],
  // },
  // {
  //   id: "additional",
  //   parent: "additional",
  //   path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_ADDITIONAL_FEATURS,   -----------------------Not Confirm--
  //   key:"",
  //   sidebarProps: {
  //     displayText: "Additional Features",
  //  icons: mdiTextSearch,//
  // icon: "bi-box-seam",
  //   },
  //   // state: "logs",
  //   child: [],
  // },
  // {
  //   id: "campaign",
  //   parent: "campaign",
  //   path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_CAMPAIGN_LEAD,
  //   sidebarProps: {
  //     displayText: "Campaign Data",
  //  icons: mdiTextSearch,//
  // icon: "bi-megaphone",
  //   },
  //   // state: "logs",
  //   child: [],
  // },
  // ---------Campaign-------------Lead and Templete
  // {
  //   id: "campaign",
  //   parent: "campaign",
  //   key: "HAS_CAMPAIGN",
  //   sidebarProps: {
  //     displayText: "Campaign",
  //     icons: mdiBullhornOutline,
  //     icon: "bi-megaphone",
  //   },
  //   // state: "manageleads",
  //   child: [
  //     // {
  //     //   id: "CampaignLead",
  //     //   parent: "campaign",
  //     //   index: true,
  //     //   path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_CAMPAIGN_LEAD,
  //     //   element: "",
  //     //   // state: "manageleads.allleads",
  //     //   sidebarProps: {
  //     //     displayText: "Campaign Lead",
  //     //  icons: mdiTextSearch,//
  //     // icon: "bi-megaphone",
  //     //   },
  //     // },
  //     {
  //       id: "bulkcampaign",
  //       parent: "campaign",
  //       index: true,
  //       key: "HAS_CAMPAIGN",
  //       path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_BULK_CAMPAIGN,
  //       element: "",
  //       // state: "manageleads.allleads",
  //       sidebarProps: {
  //         displayText: "Campaign Lead",
  //         icons: mdiAccountFilterOutline,
  //         icon: "bi-megaphone",
  //       },
  //     },
  //     {
  //       id: "templateCampaign",
  //       parent: "campaign",
  //       index: true,
  //       key: "HAS_CAMPAIGN",
  //       path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_CAMPAIGN_TEMPLATE,
  //       element: "",
  //       // state: "manageleads.allleads",
  //       sidebarProps: {
  //         displayText: "Template",
  //         icons: mdiFileChartOutline,
  //         icon: "bi-megaphone",
  //       },
  //     },
  //   ],
  // },
  // {
  //   id: "training&marketing",
  //   parent: "training&marketing",
  //   key: "HAS_TRAINING_MARKETING",
  //   path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_TRAINING_MARKETING,
  //   sidebarProps: {
  //     displayText: "Training & Marketing",
  //     // icons: mdiTextSearch,
  //     icons: mdiStackOverflow,
  //     icon: "bi bi-stack-overflow",
  //   },
  // },
  // {
  //   id: "inbound",
  //   parent: "inbound",
  //   key: "HAS_INBOUND_LEAD",
  //   path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_INBOUND_LEAD,
  //   sidebarProps: {
  //     displayText: "Inbound Lead",
  //     // icons: mdiTextSearch,
  //     icons: mdiHelpCircleOutline,
  //     icon: "bi bi-question-circle",
  //   },
  // },
  // {
  //   id: "auditorams",
  //   parent: "auditor",
  //   path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_AUDITORS_LEADS,
  //   sidebarProps: {
  //     displayText: "Auditors Lead",
  //  icons: mdiTextSearch,//
  // icon: "bi-megaphone",
  //   },
  //   // state: "logs",
  //   child: [],
  // },
  {
    id: "actionable",
    parent: "actionable",
    key: "HAS_HAS_ACTIONABLE",
    path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_ACTIONABLE_LEAD,
    sidebarProps: {
      displayText: "Actionable",
      icons: mdiTextSearch,
      icon: "bi bi-person-check",
    },
    // state: "logs",
    child: [],
  },
  // {
  //   id: "renewalnotice",
  //   parent: "renewalnotice",
  //   path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_RENEWAL_NOTICES,
  //   sidebarProps: {
  //     displayText: "Renewal Notice",
  //  icons: mdiTextSearch,//
  // icon: "bi bi-newspaper",
  //   },
  //   // state: "logs",
  //   child: [],
  // },
  {
    id: "auditorLead",
    parent: "auditorLead",
    key: "HAS_AUDITOR_ALL_LEADS",
    path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_AUDITORS_LEADS,
    sidebarProps: {
      displayText: "All Leads",
      icons: mdiTextSearch,
      icon: "bi bi-person-check",
    },
    // state: "logs",
    child: [],
  },
];
