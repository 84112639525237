// isEditingSlice.js
import { createSlice } from '@reduxjs/toolkit';

const isEditingSlice = createSlice({
  name: 'isEditing',
  initialState: false,
  reducers: {
    setIsEditing: (state, action) => action.payload,
  },
});

export const { setIsEditing } = isEditingSlice.actions;
export default isEditingSlice.reducer;
