import React, { useCallback, useEffect, useState } from "react";
import "./LeadManagmentCallStatus.scss";
import CustomButton from "../../../../shared/CustomButton";
import CustomMenuList from "../../../../shared/CustomMenuList";
import CustomTable from "../../../../shared/CustomTable";
const LeadManagmentCallStatusTableList = ({
  pagination,
  onPaginationHandler = () => {},
  tableRow = [],
  onEdit = () => {},
  onDelete = () => {},
  onReferanceClick = () => {},
  tableLoaderShow = false,
}) => {
  const [tableRowDataList, setTableRowDataList] = useState();

  const actionOptionRender = useCallback((row) => {
    return (
      <div className="action-render-list">
        <CustomMenuList>
          <div className="dropdown-item" onClick={() => {}}>
            <i
              className="align-middle me-1 bi bi-pencil-fill"
              data-feather="user"
            />
            Edit
          </div>
          <hr />
          <div className="dropdown-item" onClick={() => {}}>
            <i
              className="align-middle me-1 bi bi-trash"
              data-feather="pie-chart"
            />
            Delete
          </div>
        </CustomMenuList>
      </div>
    );
  }, []);

  const statusOptionRender = useCallback((row) => {
    return (
      <div
        className="cursor-pointer action-list-container"
        // onClick={() => {
        //   onReferanceClick(row);
        // }}
      >
        <div className="text-dark-teal fw-bold">{row?.referenceNo}</div>
      </div>
    );
  }, []);

  const attchmentRender = useCallback((row) => {
    return (
      <div className="action-render-list">
        <CustomButton className={"h-30 gap-2 bg-gray text-dark-teal fw-bold"}>
          <i className="bi bi-filetype-pdf text-dark-teal" />
          {row?.attchment}
        </CustomButton>
        {/* <div className="text-dark-teal">{row?.proposalNo}</div> */}
      </div>
    );
  }, []);

  const policyTypeRender = useCallback((row) => {
    return (
      <div className="action-render-list ">
        <CustomButton
          className={`status-btn ${
            row?.status === "Policy Done"
              ? "bg-green text-white"
              : row?.status === "Proposal"
              ? "bg-purple text-white"
              : row?.status === "Quotation"
              ? "bg-bright-orange text-white"
              : ""
          } `}
        >
          {row?.status}
        </CustomButton>
      </div>
    );
  }, []);

  const TableColumnDataList = [
    {
      id: 1,
      label: "Status",
      rowId: "StatusRender",
      position: "center",
      rowDataPosition: "center",
      isRenderItem: true,
    },
    {
      id: 2,
      label: "Reference Number",
      rowId: "referanceNumRender",
      position: "center",
      isRenderItem: true,
    },
    {
      id: 3,
      label: "Date & Time",
      rowId: "time",
      position: "center",
      isRenderItem: false,
    },
    // {
    //   id: 4,
    //   label: "Attachment",
    //   rowId: "AttachmentRender",
    //   position: "center",
    //   isRenderItem: true,
    // },
    // {
    //   id: 5,
    //   label: "Action",
    //   rowId: "actionRender",
    //   position: "center",
    //   isRenderItem: false,
    // },
  ];

  useEffect(() => {
    if (tableRow) {
      setTableRowDataList(
        tableRow?.map((value) => {
          return {
            ...value,
            StatusRender: policyTypeRender(value),
            referanceNumRender: statusOptionRender(value),
            actionRender: actionOptionRender(value),
            AttachmentRender: attchmentRender(value),
          };
        })
      );
    }
  }, [
    actionOptionRender,
    attchmentRender,
    policyTypeRender,
    statusOptionRender,
    tableRow,
  ]);

  return (
    <div className="lead-manager-call-history">
      {/* <table>
        <thead>
          <tr>
            <th>Status</th>
            <th>Referance Number</th>
            <th>Date & Time</th>
          </tr>
        </thead>
        <tbody>
          {tableRowDataList?.map((data) => (
            <tr>
              <td
                className={`h-100 text-center`}
                style={{ minWidth: "50px" }}
              >
                {data?.StatusRender}
              </td>
              <td>{data?.referanceNumRender}</td>
              <td>{data?.time}</td>
            </tr>
          ))}
        </tbody>
      </table> */}
      <CustomTable
        tableLoaderShow={tableLoaderShow}
        tableColumn={TableColumnDataList}
        tableRow={tableRowDataList}
      />
    </div>
  );
};

export default LeadManagmentCallStatusTableList;
