import settings from "./settings";
import { create } from "apisauce";

const apiClient = create({
  baseURL: settings.API_BASE_URL,
});

export default apiClient;


