import React, { useEffect, useState } from "react";
import "./NotFound.css";
const NotFound = () => {
  const [state, setState] = useState({
    isLoading: true,
  });
  useEffect(() => {
    setTimeout(() => {
      setState({ isLoading: false });
    }, 1000);
  }, []);

  return (
    <div className="not-found-container">
      {state.isLoading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <img
            className="not-found-404"
            src={require(`../../assets/img/photos/404.svg`).default}
            alt="404"
          />
          <h3 className="error-text">404</h3>
          <p>Sorry! the page you are looking for does not exist.</p>
        </>
      )}
    </div>
  );
};

export default NotFound;
