import React, { useEffect, useState } from "react";
import "./Toast.scss";
import { mdiAlertCircle } from "@mdi/js";
import Icon from "@mdi/react";

const WarningToast = ({ title, message, autoHideDuration, toastKey }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 4000);
  }, [toastKey]);

  return (
    <div
      className={`toast-container position-fixed end-0 ${
        open ? "toast-open-animation" : "toast-close-animation"
      }`}
    >
      <div
        className={`toast align-items-center bg-teal text-blue border-0 p-2 justify-content-between ${
          open ? "show" : "hide"
        }`}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div className="d-flex justify-content-between">
          <div className=" d-flex gap-2 p-2 align-items-center ">
            <Icon path={mdiAlertCircle} size={0.8} />
            <strong className="me-auto fs-6">{title}</strong>
          </div>
          <button
            type="button"
            className="btn-close text-blue me-2 m-auto"
            data-bs-dismiss="toast"
            aria-label="Close"
          />
        </div>
        {message && <div className="toast-body">{message}</div>}
      </div>
    </div>
  );
};

export default WarningToast;
