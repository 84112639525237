import React from "react";
import "./leadManagmentStatus.scss";
const LeadManagmentStatusComponent = ({
  options = [],
  currentStatus,
  ongoing,
}) => {
  return (
    <div className="card-body">
      <div className="p-1">
        <div className="lead-management-status-flow">
          {currentStatus &&
            options?.map((value, index) => {
              return (
                <div
                  key={index}
                  style={{
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                  className={`clip-path-banner text-center  
                  ${
                    currentStatus > value.id ||
                    currentStatus === options?.length
                      ? "compleated-lead-manager-process"
                      : currentStatus === value.id
                      ? "ongoing-lead-manager-process"
                      : "pending-lead-manager-process"
                  } 
                  `}
                >
                  {value.label}
                </div>
              );
            })}
          {/* <div className="clip-path-banner compleated-lead-manager-process">
            Qualified
          </div>
          <div className="clip-path-banner compleated-lead-manager-process">
            Contacted
          </div>
          <div className="clip-path-banner compleated-lead-manager-process">
            Quotation
          </div>
          <div className="clip-path-banner ongoing-lead-manager-process">
            Proposal Sent
          </div>
          <div className="clip-path-banner pending-lead-manager-process">
            Payment
          </div>
          <div className="clip-path-banner pending-lead-manager-process">
            Converted
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default LeadManagmentStatusComponent;
