export const Campaign = [
  { value: 1, label: "Diwali Festival" },
  { value: 1, label: "Raksha Bandhan" },
  { value: 1, label: "Republic Day" },
  { value: 1, label: "Independence Day" },
  { value: 1, label: "New Year" },
  { value: 1, label: "Holi Festival" },
];
export const Type = [
  { value: 1, label: "Email" },
  { value: 2, label: "Text/SMS" },
  { value: 3, label: "Whatsapp" },
  { value: 4, label: "All" },
];