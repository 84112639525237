// // types
// import { createSlice } from "@reduxjs/toolkit";

// // initial state
// const initialState = {
//   currentActiveDrawerTab: {},
//   openComponent: "buttons",
//   drawerOpen: true,
//   componentDrawerOpen: true,
//   addProductDetails: {},
//   currentActiveTab: "",
// };

// // ==============================|| SLICE - MENU ||============================== //

// const menu = createSlice({
//   name: "menu",
//   initialState,
//   reducers: {
//     currentActiveScreen(state, action) {
//       state.currentActiveDrawerTab = { ...action?.payload };
//     },

//     activeComponent(state, action) {
//       state.openComponent = action.payload.openComponent;
//     },

//     // -----------------------

//     reduxIntialUserRolePermission(state, action) {
//       state.userRolePermission = action.payload;
//     },

//     reduxSetUserRoleAllModules(state, action) {
//       const { payload } = action;
//       const { userRolePermission } = state;
//       const updatedPermissionList = userRolePermission.map((value) => {
//         if (value?.permissions && value?.permissions.length > 0) {
//           if (!payload?.enabled) {
//             const updatedChildren = value?.permissions?.map((childItem) => {
//               return {
//                 ...childItem,
//                 enabled: false,
//               };
//             });
//             return {
//               ...value,
//               enabled: payload?.enabled,
//               permissions: updatedChildren,
//             };
//           } else {
//             return {
//               ...value,
//               enabled: payload?.enabled,
//             };
//           }
//         } else {
//           return {
//             ...value,
//             enabled: payload?.enabled,
//           };
//         }
//         // return {
//         //   ...value,
//         //   enabled: payload?.enabled,
//         // };
//       });

//       state.userRolePermission = updatedPermissionList;
//     },

//     reduxSetUserRoleModule(state, action) {
//       const { payload } = action;
//       const { userRolePermission } = state;
//       const updatedPermissionList = userRolePermission.map((value) => {
//         if (value?.module_id === payload?.module?.module_id) {
//           if (value?.permissions && value?.permissions.length > 0) {
//             if (!payload?.enabled) {
//               const updatedChildren = value?.permissions.map((childItem) => {
//                 return {
//                   ...childItem,
//                   enabled: false,
//                 };
//               });
//               return {
//                 ...value,
//                 permissions: updatedChildren,
//                 enabled: payload?.enabled,
//               };
//             } else {
//               return {
//                 ...value,
//                 enabled: payload?.enabled,
//               };
//             }
//           } else {
//             return {
//               ...value,
//               enabled: payload?.enabled,
//             };
//           }
//         }
//         return value;
//       });

//       state.userRolePermission = updatedPermissionList;
//     },

//     reduxSetUserRoleAllPermission(state, action) {
//       const { payload } = action;
//       const { userRolePermission } = state;
//       const updatedPermissionList = userRolePermission.map((value) => {
//         if (value?.module_id === payload?.module?.module_id) {
//           if (value?.permissions && value?.permissions.length > 0) {
//             const updatedChildren = value?.permissions?.map((childItem) => {
//               return {
//                 ...childItem,
//                 enabled: payload.enabled,
//               };
//             });
//             return {
//               ...value,
//               enabled: true,
//               permissions: updatedChildren,
//             };
//           } else {
//             return {
//               ...value,
//               enabled: payload?.enabled,
//             };
//           }
//         }
//         return value;
//       });

//       state.userRolePermission = updatedPermissionList;
//     },

//     reduxSetUserRolePermission(state, action) {
//       const { payload } = action;
//       const { userRolePermission } = state;
//       const updatedPermissionList = userRolePermission.map((value) => {
//         if (value?.module_id === payload?.module?.module_id) {
//           if (value?.permissions && value?.permissions.length > 0) {
//             const updatedChildren = value?.permissions?.map((childItem) => {
//               if (
//                 childItem?.permission_id === payload?.peramis?.permission_id
//               ) {
//                 return {
//                   ...childItem,
//                   enabled: payload.enabled,
//                 };
//               } else {
//                 return {
//                   ...childItem,
//                 };
//               }
//             });
//             return {
//               ...value,
//               enabled: true,
//               permissions: updatedChildren,
//             };
//           } else {
//             return {
//               ...value,
//               enabled: payload?.enabled,
//             };
//           }
//         }
//         return value;
//       });

//       state.userRolePermission = updatedPermissionList;
//     },
//     // ------------------------------------
//     openDrawer(state, action) {
//       state.drawerOpen = action.payload.drawerOpen;
//     },

//     openComponentDrawer(state, action) {
//       state.componentDrawerOpen = action.payload.componentDrawerOpen;
//     },
//     setCurrentActiveTab(state, action) {
//       state.currentActiveTab = action.payload.activeTab;
//     },
//     resetCurrentActiveTab(state, action) {
//       // state.activeTab = action.payload.activeTab;
//       state.currentActiveTab = "";
//     },
//   },
// });

// export default menu.reducer;

// export const {
//   reduxIntialUserRolePermission,
//   reduxSetUserRoleAllModules,
//   reduxSetUserRoleModule,
//   reduxSetUserRoleAllPermission,
//   reduxSetUserRolePermission,
//   currentActiveScreen,
//   activeComponent,
//   openDrawer,
//   openComponentDrawer,
//   setCurrentActiveTab,
//   resetCurrentActiveTab,
// } = menu.actions;

// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  currentActiveDrawerTab: {},
  openComponent: "buttons",
  userRolePermission: [],
  activeUserRoleTab: null,
  userPermission: [],
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setUserPermission(state, action) {
      state.userPermission = { ...action?.payload };
    },

    reduxSetActiveUserRoleTab(state, action) {
      state.activeUserRoleTab = { ...action?.payload };
    },

    currentActiveScreen(state, action) {
      state.currentActiveDrawerTab = { ...action?.payload };
    },

    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },

    reduxIntialUserRolePermission(state, action) {
      state.userRolePermission = action.payload;
    },

    reduxGeneralProduct(state, action) {
      state.permissionList = action.payload;
    },

    reduxSetUserRoleModule(state, action) {
      const { payload } = action;
      const { userRolePermission } = state;
      const updatedPermissionList = userRolePermission.map((value) => {
        if (value?.module_id === payload?.module?.module_id) {
          if (value?.permissions && value?.permissions.length > 0) {
            if (!payload?.enabled) {
              const updatedChildren = value?.permissions.map((childItem) => {
                return {
                  ...childItem,
                  permission_enabled: false,
                };
              });
              return {
                ...value,
                permissions: updatedChildren,
                enabled: payload?.enabled,
              };
            } else {
              return {
                ...value,
                enabled: payload?.enabled,
              };
            }
          } else {
            return {
              ...value,
              enabled: payload?.enabled,
            };
          }
        }
        return value;
      });

      state.userRolePermission = updatedPermissionList;
    },

    reduxSetUserRolePermission(state, action) {
      const { payload } = action;
      const { userRolePermission } = state;
      const updatedPermissionList = userRolePermission.map((value) => {
        if (value?.module_id === payload?.module?.module_id) {
          if (value?.permissions && value?.permissions.length > 0) {
            const updatedChildren = value?.permissions?.map((childItem) => {
              if (childItem.permission_id === payload?.peramis?.permission_id) {
                return {
                  ...childItem,
                  permission_enabled: payload.enabled,
                };
              } else {
                return {
                  ...childItem,
                };
              }
            });
            return {
              ...value,
              enabled: true,
              permissions: updatedChildren,
            };
          } else {
            return {
              ...value,
              enabled: payload?.enabled,
            };
          }
        }
        return value;
      });

      state.userRolePermission = updatedPermissionList;
    },

    reduxSetUserRoleAllModules(state, action) {
      const { payload } = action;
      const { userRolePermission } = state;
      const updatedPermissionList = userRolePermission.map((value) => {
        if (value?.permissions && value?.permissions.length > 0) {
          if (!payload?.enabled) {
            const updatedChildren = value?.permissions?.map((childItem) => {
              return {
                ...childItem,
                permission_enabled: false,
              };
            });
            return {
              ...value,
              enabled: payload?.enabled,
              permissions: updatedChildren,
            };
          } else {
            return {
              ...value,
              enabled: payload?.enabled,
            };
          }
        } else {
          return {
            ...value,
            enabled: payload?.enabled,
          };
        }
        // return {
        //   ...value,
        //   enabled: payload?.enabled,
        // };
      });

      state.userRolePermission = updatedPermissionList;
    },

    reduxSetUserRoleAllPermission(state, action) {
      const { payload } = action;
      const { userRolePermission } = state;
      const updatedPermissionList = userRolePermission.map((value) => {
        if (value?.module_id === payload?.module?.module_id) {
          if (value?.permissions && value?.permissions.length > 0) {
            const updatedChildren = value?.permissions?.map((childItem) => {
              return {
                ...childItem,
                permission_enabled: payload.enabled,
              };
            });
            return {
              ...value,
              enabled: true,
              permissions: updatedChildren,
            };
          } else {
            return {
              ...value,
              enabled: payload?.enabled,
            };
          }
        }
        return value;
      });

      state.userRolePermission = updatedPermissionList;
    },
  },
});

export default menu.reducer;

export const {
  currentActiveScreen,
  activeComponent,
  reduxIntialUserRolePermission,
  reduxSetUserRoleModule,
  reduxSetUserRolePermission,
  reduxSetUserRoleAllModules,
  reduxSetUserRoleAllPermission,
  reduxSetActiveUserRoleTab,
} = menu.actions;
