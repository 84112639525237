import React, { useContext, useEffect } from "react";
import "./VehicalInsurence.scss";
import { Outlet, useNavigate } from "react-router-dom";
import SidebarDrawer from "../../SidebarDrawer";
import GlobalContext from "../../../context/GlobalContext";

const VehicalInsurence = () => {
  const navigate = useNavigate();
  const { isDashboardDrawerOpen } = useContext(GlobalContext);

  useEffect(() => {
    // navigate(ROUTES_NAVIGATION.VEHICLE_INSURENCE_DASHBOARD, { replace: true });
  }, [navigate]);

  return (
    <div className="d-flex vehicle-insurance-main-layout-container">
      <div className="vehicle-insurance-main-layout-sidebar">
        <SidebarDrawer />
      </div>

      <div
        className={`container-fluid  vehicle-insurance-main-layout-container-page ${
          isDashboardDrawerOpen ? "open-drawer" : "closed-drawer"
        }`}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default VehicalInsurence;
