import React, { lazy, useContext, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ROUTES_NAVIGATION from "./routes";
import NotFound from "../pages/NotFound";
import VehicleInsurenceRoute from "./VehicleInsurence";
import Loadable from "../pages/Loadable";
import GlobalContext from "../context/GlobalContext";

const MainLayout = Loadable(lazy(() => import("../pages/MainLayout")));
const HomePage = Loadable(lazy(() => import("../pages/HomePage")));
const LoginPage = Loadable(lazy(() => import("../pages/LoginPage")));
const TermsAndConditionsPage = Loadable(
  lazy(() => import("../pages/TermsAndConditions"))
);

const RouteComponent = () => {
  const { isUserAuth, setIsUserAuth } = useContext(GlobalContext);

  useEffect(() => {
    if (localStorage.getItem("isAuthenticate") === "true") {
      setIsUserAuth(true);
    } else {
      setIsUserAuth(false);
    }
  }, [setIsUserAuth]);

  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<HomePage />} />
        <Route
          path={`${ROUTES_NAVIGATION.VEHICLE_INSURENCE}/*`}
          element={<VehicleInsurenceRoute />}
        />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route
        path={ROUTES_NAVIGATION.LOGIN}
        element={isUserAuth ? <Navigate to={"/"} /> : <LoginPage />}
      />
      <Route
        path={ROUTES_NAVIGATION.TERMS_AND_CONDITIONS}
        element={<TermsAndConditionsPage />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default RouteComponent;
