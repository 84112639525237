// src/store/selectedIdsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedIds: [],
  selectedProductIds: [],
};

const selectedIdsSlice = createSlice({
  name: 'selectedIds',
  initialState,
  reducers: {
    setLeadId: (state, action) => {
        state.selectedIds = action.payload;
      },
      setleadProductId: (state, action) => {
        state.selectedProductIds = action.payload;
      }
  }
});

export const { setLeadId, setleadProductId } = selectedIdsSlice.actions;

export default selectedIdsSlice.reducer;
