import Cookies from "universal-cookie";
import settings from "./settings";
import { create } from "apisauce";

export const loginClient = create({
  baseURL: settings.AUTH_API_BASE_URL,
});

const cookies = new Cookies();
const getToken = async () => {
  try {
    return await cookies.get("access_token");
  } catch (e) {
    console.log("Error getting access token: " + e);
  }
};

const authClient = create({
  baseURL: settings.AUTH_API_BASE_URL,
});

authClient.addAsyncRequestTransform(async (request) => {
  const authToken = await getToken();
  console.log("auth token", authToken);
  if (!authToken) {
    return;
  }
  request.headers["Authorization"] = "Bearer " + authToken;
  // request.headers["Content-Type"] = "multipart/form-data";
  request.headers["Content-Type"] = "application/json";
  // request.headers["Accept"] = "*/*";
  // if not working remove return
  return request;
});

authClient.addResponseTransform(
  (response) => {
    console.log("response---token", response);
    if (response.status === 401) {
      cookies.remove("access_token");
      cookies.remove("refresh_token");
      localStorage.clear();
      window.location.href = "/login";
      alert("Session is Expired");
    }
  },
  () => {
    console.log("Token Invalid");
  }
);

export default authClient;
