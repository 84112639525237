import React, { useState } from "react";
import MainCard from "../../../shared/MainCard";
import ROUTES_NAVIGATION from "../../../../routes/routes";
import CustomButton from "../../../shared/CustomButton";
import { useNavigate } from "react-router-dom";
import CustomSelect from "../../../shared/CustomSelect";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";
import "./AllTemplate.scss"
import { Campaign, Type } from "./addJsonData";

const PageRoutes = [
  {
    path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_CAMPAIGN_LEAD,
    label: "Campaigns",
  },
  {
    path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_CAMPAIGN_TEMPLATE,
    label: " Templates / Create Template",
  },
];

const CreateCampaignTemplate = () => {
  const navigate = useNavigate();
  const [editorData, setEditorData] = useState("");

  return (
    <MainCard title="Create Template" pageRoutes={PageRoutes} isBack>
      <div className="create-campaign-template-main-container">
        <div className="">
          <div className="create-campaign-type-container">
            <div className="pb-3">
              <div className="row g-2">
                <div
                  className="col-12 col-sm-4 col-md-3 col-lg-2"
                 
                >
                  <label className="mb-2">Campaign Name</label>
                  <span className="text-red ms-1">*</span>
                  <CustomSelect
                    placeholder="Select Campaign Name"
                    label="Compaign Name"
                    required
                    options={Campaign}
                  />
                </div>
                <div className="col-12  col-sm-4 col-md-3 col-lg-2">
                  <label className="mb-2">Type</label>
                  <span className="text-red ms-1">*</span>
                  <CustomSelect
                    placeholder={"Select Type"}
                    label="Type"
                    required
                    options={Type}
                  />
                </div>
                {/* <div className="col-12 col-sm-12 col-md-3 col-lg-8 align-items-center d-flex justify-content-end">
                  <CustomButton
                    className="text-dark-teal rounded-4 gap-2 font-8"
                    onClick={() => {
                      navigate(
                        ROUTES_NAVIGATION.VEHICLE_INSURENCE_CREATE_CAMPAIGN_DATA_TEMPLATES
                      );
                    }}
                  >
                    <i className="bi bi-upload"></i>
                    <span className="">Upload Template</span>
                  </CustomButton>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <CKEditor
              editor={ClassicEditor}
              data={editorData}
              onReady={(editor) => {
                console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setEditorData(data);
              }}
              onBlur={(event, editor) => {
                console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                console.log("Focus.", editor);
              }}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end gap-2 mt-4">
          <CustomButton className="discardBtn px-6 text-dark-teal rounded-4  font-8">
            <span className="">Discard</span>
          </CustomButton>
          <CustomButton className="saveBtn  text-blue bg-yellow rounded-4 font-8">
            <span className="">Save</span>
          </CustomButton>
        </div>
      </div>
    </MainCard>
  );
};

export default CreateCampaignTemplate;
