// import React, { useEffect, useState } from "react";

// import "./CustomDateInput.scss";
// import ReactDatePicker from "react-datepicker";
// import Icon from "@mdi/react";
// import { mdiCalendar } from "@mdi/js";

// const CustomDateInput = ({
//   isDateRange = true,
//   dateRange = [],
//   setDateRange,
//   isClearable = false,
//   className,
//   onChange = () => {},
//   placeholderText = "Enter date",
//   selected,
//   label = "",
//   required = false,
//   showTimeSelect = false,
//   showTimeSelectOnly = false,
//   showMonthYearPicker = false,
//   dateFormat = "DD-MM-YYYY",
//   // dateFormat = "yyyy/MM/dd",
//   disabled = false,
//   minDate = null,
// }) => {
//   // const [dateRange, setDateRange] = useState([null, null]);

//   const [startDate, endDate] = dateRange;

//   const [startDateTime, setStartDateTime] = useState();
//   // setHours(setMinutes(new Date(), 0), 9)

//   const filterPassedTime = (time) => {
//     const currentDate = new Date();
//     const selectedDate = new Date(time);

//     return currentDate.getTime() < selectedDate.getTime();
//   };
//   console.log("selectedDate", selected);
//   if (selected === "Invalid date") {
//     selected = null;
//   }

//   return (
//     <div className="custom-date-input">
//       {label && (
//         <div>
//           <label
//             htmlFor={label}
//             className={`select-form-label fw-bold ${
//               required ? "required" : ""
//             }`}
//           >
//             {label}
//           </label>
//         </div>
//       )}

//       <ReactDatePicker
//         // selected={selected || null}
//         // selected={!isDateRange ? selected : new Date()}
//         className={` ${className}`}
//         showIcon
//         showTimeSelect={showTimeSelect}
//         onKeyDown={(e) => e.preventDefault()}
//         showTimeSelectOnly={showTimeSelectOnly}
//         // timeIntervals={30}
//         timeCaption="Next Follow Time"
//         // dateFormat="h:mm aa"
//         dateFormat={dateFormat}
//         toggleCalendarOnIconClick
//         selectsRange={isDateRange}
//         showMonthYearPicker={showMonthYearPicker}
//         startDate={startDate}
//         endDate={endDate}
//         onChange={(update) => {
//           console.log("update", update);
//           if (isDateRange) {
//             setDateRange(update);
//           } else {
//             onChange(update);
//           }
//         }}
//         isClearable={isClearable}
//         placeholderText={placeholderText}
//         icon={<Icon path={mdiCalendar} className="" />}
//         filterTime={filterPassedTime}
//         disabled={disabled}
//         minDate={minDate}
//       />
//     </div>
//   );
// };

// export default CustomDateInput;

import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import Icon from "@mdi/react";
import { mdiCalendar } from "@mdi/js";
import "./CustomDateInput.scss";
import "react-datepicker/dist/react-datepicker.css"; // Ensure the CSS is imported

const CustomDateInput = ({
  isDateRange = true,
  dateRange = [],
  setDateRange,
  isClearable = false,
  className = "",
  onChange = () => {},
  placeholderText = "Enter date",
  selected,
  label = "",
  required = false,
  showTimeSelect = false,
  showTimeSelectOnly = false,
  showMonthYearPicker = false,
  showYearPicker = false,
  dateFormat = "dd-MM-yyyy",
  minDate,
  maxDate,
  minTime,
  maxTime,
  error = false,
  errorsMessage = "required",
  disabled = false,
}) => {
  const [startDate, endDate] = dateRange;
  // const [selectedDate, setSelectedDate] = useState(selected);

  if (selected === "Invalid date") {
    selected = null;
  }
  console.log("Customselected", selected);

  const safeDate =
    selected instanceof Date && !isNaN(selected) ? selected : new Date();

  // console.log("--->>", new Date().setDate(new Date().getDate() - 70));
  const minYear = minDate ? new Date(minDate)?.getFullYear() : 1950;
  const maxYear = maxDate
    ? new Date(maxDate)?.getFullYear()
    : new Date().getFullYear() + 5;
  const minMonth = minDate ? new Date(minDate)?.getMonth() : 0;
  const maxMonth = maxDate ? new Date(maxDate)?.getMonth() : 11;

  return (
    <div className="custom-date-input">
      {label && (
        <label
          htmlFor={label}
          className={`select-form-label ${required ? "required" : ""}`}
        >
          {label}
        </label>
      )}
      <ReactDatePicker
        selected={selected ? selected : null}
        className={` ${className}`}
        disabled={disabled}
        showIcon
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        dateFormat={dateFormat}
        toggleCalendarOnIconClick
        selectsRange={isDateRange}
        showMonthYearPicker={showMonthYearPicker}
        showYearPicker={showYearPicker}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        minTime={minTime}
        maxTime={maxTime}
        // timeIntervals={1}
        onChange={(update) => {
          if (isDateRange) {
            setDateRange(update);
          } else {
            onChange(update);
          }
        }}
        isClearable={isClearable}
        placeholderText={placeholderText}
        icon={<Icon path={mdiCalendar} className="" />}
        onKeyDown={(e) => e.preventDefault()}
        renderCustomHeader={({ date, changeYear, changeMonth }) => {
          const currentYear = new Date().getFullYear();
          const displayedDate = (date && new Date(date)) || safeDate;

          const selectedYear = displayedDate.getFullYear();
          const selectedMonth = displayedDate.getMonth();

          return (
            <div className="d-flex justify-content-center m-2">
              <select
                value={selectedYear}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {Array.from({ length: maxYear - minYear + 1 }, (_, i) => (
                  <option key={i} value={minYear + i}>
                    {minYear + i}
                  </option>
                ))}
              </select>

              <select
                value={selectedMonth}
                onChange={({ target: { value } }) => changeMonth(value)}
                // disabled={selectedYear === minYear || selectedYear === maxYear}
              >
                {Array.from({ length: 12 }, (_, i) => {
                  if (
                    (selectedYear === minYear && i < minMonth) ||
                    (selectedYear === maxYear && i > maxMonth)
                  ) {
                    return null;
                  }
                  return (
                    <option key={i} value={i}>
                      {new Date(0, i)?.toLocaleString("default", {
                        month: "long",
                      })}
                    </option>
                  );
                })}
              </select>
            </div>
          );
        }}
      />
      {error ? <div className="error-message">{errorsMessage}</div> : null}
    </div>
  );
};

export default CustomDateInput;
