import React from "react";
import "./CustomMenuList.scss";

const CustomMenuList = (props) => {
  const { className } = props;
  return (
    <div className={`custom-menu-container ${className}`}>
      <div
        type="button"
        id="moreOptionMenu"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i
          className="align-middle me-1 bi bi-three-dots-vertical"
          data-feather="user"
        />
      </div>
      <ul className="dropdown-menu" aria-labelledby="moreOptionMenu">
        {props?.children}
      </ul>
    </div>
  );
};

export default CustomMenuList;
