// import React, { useState } from "react";
// import "./CustomTab.scss";

// const CustomTab = (props) => {
//   const {
//     // tabList = [
//     //   { id: "fresh", label: "Fresh" },
//     //   { id: "open", label: "Open" },
//     //   { id: "closed", label: "Closed" },
//     // ],
//     tabList = [
//       { id: "forme", label: "For Me" },
//       { id: "done", label: "Done" },
//     ],
//     onClick = () => {},
//     activeTab = { id: "open", label: "Open" },
//     style,
//   } = props;
//   const [activeTabIndex, setActiveTabIndex] = useState(activeTab);

//   const onClickHandler = (value) => {
//     setActiveTabIndex(value);
//     onClick(value);
//   };

//   return (
//     <div className="container-fluid custom-tabs-main-container">
//       <div className="d-flex justify-content-between me-3 ms-3">
//         {/* border border-1 */}
//         <ul className="nav nav-tabs">
//           {/* {tabList?.map((value) => {
//             return (
//               <li
//                 key={value?.id}
//                 style={style}
//                 className={`custom-tab-label ${
//                   activeTabIndex?.id === value?.id ? "active" : ""
//                 }`}
//                 onClick={() => {
//                   onClickHandler(value);
//                 }}
//               >
//                 {value?.icon && <i className={`bi ${value?.icon}`} />}
//                 <div>{value?.label}</div>
//               </li>
//             );
//           })} */}
//           {/* <li className={`custom-tab-label ${false ? "active" : ""}`}>
//           <div>Home</div>
//         </li> */}
//         </ul>
//         {props?.children}

//         {/* <div className="d-flex justify-content-end align-items-center">
//           <div className="d-flex align-items-center me-auto">
//             <div className="d-flex align-items-center me-2">
//               <label htmlFor="startDate" className="me-2">
//                 From
//               </label>
//               <input type="date" id="startDate" className="form-control" />
//             </div>
//             <div className="d-flex align-items-center me-2">
//               <label htmlFor="endDate" className="me-2">
//                 To
//               </label>
//               <input type="date" id="endDate" className="form-control" />
//             </div>
//           </div>
//           <div className="d-flex align-items-center">
//             <select className="form-control me-2">
//               <option value="New">New</option>
//               <option value="In Progress">Renew</option>
//               <option value="Completed">Roll Over</option>
//             </select>
//             <button
//               className="btn"
//               style={{ backgroundColor: "rgba(220, 211, 238, 1)" }}
//             >
//               <i
//                 class="bi bi-arrow-clockwise"
//                 style={{ color: "rgba(66, 133, 244, 1)" }}
//               />
//             </button>
//           </div>
//         </div> */}
//       </div>
//     </div>
//   );
// };

// export default CustomTab;

import React, { useEffect, useState } from "react";
import "./CustomTab.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faFileExport } from "@fortawesome/free-solid-svg-icons";
import { Grow } from "@mui/material";

const CustomTab = (props) => {
  const {
    tabList = [],
    onClick = () => {},
    activeTab = { id: "done", label: "Done" },
    style,
    leadsCounting,
    expandStyle,
  } = props;
  const [activeTabIndex, setActiveTabIndex] = useState(activeTab);

  const onClickHandler = (value) => {
    setActiveTabIndex(value);
    onClick(value);
  };

  useEffect(() => {
    if (activeTab) {
      setActiveTabIndex(activeTab);
    }
  }, [activeTab]);
  return (
    <div className="container-fluid custom-tabs-main-container">
      <div className="container-fluid" style={{ position: "relative" }}>
        <div className="custom-tabs-header flex-wrap">
          <ul className={`nav nav-tabs`} style={expandStyle}>
            {tabList?.map((value) => (
              <li
                key={value?.id}
                style={style}
                className={`custom-tab-label pb-2 ${
                  activeTabIndex?.id === value?.id ? "active" : ""
                }`}
                onClick={() => {
                  onClickHandler(value);
                }}
              >
                {value?.icon && <i className={`bi ${value?.icon}`} />}
                <div className="custom-tab-label-text">{value?.label}</div>
              </li>
            ))}
          </ul>
          {props?.children}
        </div>
      </div>
    </div>
  );
};

export default CustomTab;
